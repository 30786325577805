import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(255,255,255,0.13)",
    marginBottom: ({ marginBottom }) => `${marginBottom}px`,
    "&::after": {
      background: "linear-gradient(90deg, transparent, rgba(255, 255, 255, .05), transparent)",
    },
  },
});

const BaseSkeleton = ({ width = "100%", marginBottom = 0 }) => {
  const styles = useStyles({ marginBottom });
  return <Skeleton className={styles.root} animation="wave" width={width} />;
};

const LoadingCard = () => {
  return (
    <div className="bg--mid-gray-v2 br--4 pa--24 white tile">
      <BaseSkeleton width="25%" marginBottom={20} />
      <BaseSkeleton />
      <BaseSkeleton width="75%" marginBottom={20} />
      <BaseSkeleton width="50%" />
    </div>
  );
};

export default LoadingCard;
