import React from "react";
import { MdOutlineClose, MdEast } from "react-icons/md";
import Nav from "./Nav";

const OverlayLayout = ({
  user = "Joe Goose",
  isOpen,
  closeOverlay,
  children,
  link = "/",
  linkText,
  hasDownloadButton = false,
}) => {
  if (isOpen) {
    return (
      <div className="overlay">
        <Nav user={user} hasLogos={false} />
        <div className="overlay__aside">
          <div className="overlay__group">
            <button className="overlay__button overlay__button--icon br--pill" onClick={() => closeOverlay(!isOpen)}>
              <MdOutlineClose />
            </button>
            {hasDownloadButton && <button className="overlay__button overlay__button--secondary">Download PDF</button>}
          </div>
          <a className="overlay__button overlay__button--flex overlay__button--primary" href={link}>
            {linkText} <MdEast />
          </a>
        </div>
        <div className="overlay__wrapper">
          <div className="overlay__body">{children}</div>
        </div>
      </div>
    );
  }
};

export default OverlayLayout;
