import React, { createContext, useContext } from "react";
import BaseCard from "./BaseCard";
import { Esp } from "../../Logos";

const EspErrorCardContext = createContext(null);

const useEspErrorCardContext = () => {
  const context = useContext(EspErrorCardContext);
  if (!context) {
    throw new Error("useEspErrorCardContext must be used within an EspErrorCardProvider");
  }
  return context;
};

function EspErrorCard({ error, children }) {
  return (
    <EspErrorCardContext.Provider value={{ error }}>
      <BaseCard>
        <BaseCard.Header>
          <BaseCard.BadgeContainer className="bg--badge">
            <Esp width={14} height={14} />
            <span>ESP</span>
          </BaseCard.BadgeContainer>
          <BaseCard.TagContainer className="bg--tag light-gray">(--%)</BaseCard.TagContainer>
        </BaseCard.Header>
        <BaseCard.Section>{children}</BaseCard.Section>
      </BaseCard>
    </EspErrorCardContext.Provider>
  );
}

function LineChart({ className }) {
  useEspErrorCardContext();

  return (
    <svg viewBox="0 0 240 60" className={className}>
      <polyline
        fill="none"
        stroke="rgba(40, 125, 252, 0.25)"
        strokeWidth="3"
        points=" 0,60 10,47 20,50 30,44 40,36 50,34 60,50 70,24 80,54 90,46 100,10 110,34 120,14 130,16 140,32 150,24 160,44 170,26 180,20 190,24 200,14 210,16 220,12 230,24 240,14"
      />
      <text x="50%" y="34" textAnchor="middle" fontFamily="Poppins" fontSize="12" fill="#adbbc8">
        Data Unavailable
      </text>
    </svg>
  );
}

function BarChart({ className }) {
  useEspErrorCardContext();

  return (
    <svg viewBox="0 0 340 8" className={className}>
      <rect width="263" height="8" fill="#33c197" opacity=".25"></rect>
      <rect x="263" width="73" height="8" fill="#fec767" opacity=".25"></rect>
      <text x="50%" y="7" textAnchor="middle" fontFamily="Poppins" fontSize=".625rem" fill="#adbbc8">
        Data Unavailable
      </text>
    </svg>
  );
}

function PieChart() {
  useEspErrorCardContext();

  return (
    <svg cx="50%" cy="50%" width="100%" height="148" viewBox="0 0 215 148">
      <path
        cx="107.5"
        cy="70"
        fill="#28D0FC"
        opacity=".25"
        d="M 169.5,70A 62,62,0,0,0,95.40440003500005,9.191312614999717L 97.82352002800005,21.35305009199977A 49.6,49.6,0,0,1,157.1,70 Z"
      ></path>
      <path
        cx="107.5"
        cy="70"
        fill="#24B668"
        opacity=".25"
        d="M 95.40440003500005,9.191312614999717A 62,62,0,0,0,46.69131261499972,57.90440003500005L 58.85305009199977,60.32352002800005A 49.6,49.6,0,0,1,97.82352002800005,21.35305009199977 Z"
      ></path>
      <path
        cx="107.5"
        cy="70"
        fill="#E91E63"
        opacity=".25"
        d="M 43.74895677379002,57.31912906895167A 65,65,0,0,0,61.5380592228744,115.96194077712559L 74.54882399670689,102.95117600329311A 46.6,46.6,0,0,1,61.79540593320946,60.90879099404843 Z"
      ></path>
      <path
        cx="107.5"
        cy="70"
        fill="#287DFC"
        opacity=".25"
        d="M 63.65937956643404,113.84062043356595A 62,62,0,0,0,141.94535444721532,121.55111596275782L 135.05628355777225,111.24089277020626A 49.6,49.6,0,0,1,72.42750365314723,105.07249634685274 Z"
      ></path>
      <path
        cx="107.5"
        cy="70"
        fill="#2BD67B"
        opacity=".25"
        d="M 141.94535444721532,121.55111596275782A 62,62,0,0,0,169.5,70.00000000000001L 157.1,70.00000000000001A 49.6,49.6,0,0,1,135.05628355777225,111.24089277020626 Z"
      ></path>
      <text x="50%" y="74" textAnchor="middle" fontFamily="Poppins" fontSize="12" fill="#adbbc8">
        Data Unavailable
      </text>
    </svg>
  );
}

function Copy() {
  useEspErrorCardContext();
  return (
    <>
      <BaseCard.CopyContainer>ESP data is not currently available for your account.</BaseCard.CopyContainer>
      <BaseCard.SecondaryText>--</BaseCard.SecondaryText>
    </>
  );
}

function ChartCopy() {
  useEspErrorCardContext();
  return (
    <>
      <BaseCard.CopyContainer>There is not enough data to populate this graph.</BaseCard.CopyContainer>
      <BaseCard.SecondaryText>--</BaseCard.SecondaryText>
    </>
  );
}

EspErrorCard.LineChart = LineChart;
EspErrorCard.BarChart = BarChart;
EspErrorCard.PieChart = PieChart;
EspErrorCard.ChartCopy = ChartCopy;
EspErrorCard.Copy = Copy;

export default EspErrorCard;
