import React, { useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import AuthLayout from "./Layout";

const CreateAccount = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <AuthLayout title="Create a new account">
      <form className="form" method="POST" action={`/organization/${props.org}/setpassword?token=${props.token}`}>
        <div className="form__field">
          {/* TODO: fix email label styling */}
          <input
            id="email"
            name="session[email]"
            className="form__input"
            type="text"
            value={props.email}
            required
            readOnly
          />
          <label htmlFor="email" className="form__label form__label--float">
            Email Address*
          </label>
        </div>
        <div className="form__field">
          <input
            id="password"
            name="session[password]"
            className="form__input"
            type={showPassword ? "text" : "password"}
            required
          />
          <label htmlFor="password" className="form__label form__label--float">
            Password*
          </label>
          <button type="button" onClick={() => setShowPassword(!showPassword)} className="form__icon">
            {showPassword ? <MdOutlineVisibility size={24} /> : <MdOutlineVisibilityOff size={24} />}
          </button>
        </div>
        <div className="form__field">
          <input
            id="confirm-password"
            name="session[confirm_password]"
            className="form__input"
            type={showConfirmPassword ? "text" : "password"}
            required
          />
          <label htmlFor="confirm-password" className="form__label form__label--float">
            Confirm Password*
          </label>
          <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="form__icon">
            {showConfirmPassword ? <MdOutlineVisibility size={24} /> : <MdOutlineVisibilityOff size={24} />}
          </button>
        </div>
        <div className="form__footer">
          <div className="form__info">Creating an account means you agree to our Terms of Use and Privacy Policy.</div>
          <button className="form__button" type="submit">
            Create Account
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default CreateAccount;
