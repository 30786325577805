import React from "react";
import * as RadixPopover from "@radix-ui/react-popover";

const Popover = ({ trigger, children }) => {
  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Content sideOffset={6} align="start" alignOffset={-6} className="popover z--100">
        {children}
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
};

export default Popover;
