import React from "react";
import OverlayLayout from "./OverlayLayout";

import roundtableImgSrc from "../assets/images/roundtable.jpg";
import roundtableAuthorImgSrc from "../assets/images/roundtable-author.jpg";

const RoundTableOverlay = (props) => {
  return (
    <OverlayLayout link="/" linkText="Open RoundTable" {...props}>
      <div className="roundtable">
        <div className="roundtable__publisher">Published by BRG HAP</div>
        <article className="roundtable__article">
          <div className="roundtable__issue">January Round Up</div>
          <div className="roundtable__header">
            <img className="roundtable__image" src={roundtableImgSrc} alt="pills on an orange background" />
            <div className="roundtable__wrapper">
              <h2 className="roundtable__title">340B Sales per Covered Entity by HRSA</h2>
              <div className="roundtable__byline">
                <img
                  className="roundtable__image roundtable__image--author br--round"
                  src={roundtableAuthorImgSrc}
                  alt="author"
                />
                <div className="roundtable__wrapper">
                  <div className="roundtable__author">By Eleanor Blalock</div>
                  <div className="roundtable__date">Jan 23, 2023</div>
                </div>
              </div>
            </div>
          </div>
          <div className="roundtable__body">
            <p>
              Based on discussion at the recent 340B Coalition Summer Conference, the covered entity community is
              considering different strategies to retain access to 340B pricing at contract pharmacies for those
              products subject to a manufacturer contract pharmacy policy. Under one strategy, covered entity in- house
              pharmacies would receive shipments of medication purchased at the 340B price from wholesalers, and
              subsequently ship those units to one or more contract pharmacies for dispense. Manufacturers would be
              blind to the secondary shipment and would only see the purchase as a shipment to a 340B covered entity
              location. It is not clear whether any covered entities are using this strategy yet, and one presenter
              cautioned that the strategy presents certain regulatory risks that must be considered carefully. This
              month, we will examine whether and how existing regulations may allow covered entities to engage in this
              type of redistribution.
            </p>
            <p>
              Based on discussion at the recent 340B Coalition Summer Conference, the covered entity community is
              considering different strategies to retain access to 340B pricing at contract pharmacies for those
              products subject to a manufacturer contract pharmacy policy. Under one strategy, covered entity in- house
              pharmacies would receive shipments of medication purchased at the 340B price from wholesalers, and
              subsequently ship those units to one or more contract pharmacies for dispense. Manufacturers would be
              blind to the secondary shipment and would only see the purchase as a shipment to a 340B covered entity
              location. It is not clear whether any covered entities are using this strategy yet, and one presenter
              cautioned that the strategy presents certain regulatory risks that must be considered carefully. This
              month, we will examine whether and how existing regulations may allow covered entities to engage in this
              type of redistribution.
            </p>
            <p>
              Based on discussion at the recent 340B Coalition Summer Conference, the covered entity community is
              considering different strategies to retain access to 340B pricing at contract pharmacies for those
              products subject to a manufacturer contract pharmacy policy. Under one strategy, covered entity in- house
              pharmacies would receive shipments of medication purchased at the 340B price from wholesalers, and
              subsequently ship those units to one or more contract pharmacies for dispense. Manufacturers would be
              blind to the secondary shipment and would only see the purchase as a shipment to a 340B covered entity
              location. It is not clear whether any covered entities are using this strategy yet, and one presenter
              cautioned that the strategy presents certain regulatory risks that must be considered carefully. This
              month, we will examine whether and how existing regulations may allow covered entities to engage in this
              type of redistribution.
            </p>
            <p>
              Based on discussion at the recent 340B Coalition Summer Conference, the covered entity community is
              considering different strategies to retain access to 340B pricing at contract pharmacies for those
              products subject to a manufacturer contract pharmacy policy. Under one strategy, covered entity in- house
              pharmacies would receive shipments of medication purchased at the 340B price from wholesalers, and
              subsequently ship those units to one or more contract pharmacies for dispense. Manufacturers would be
              blind to the secondary shipment and would only see the purchase as a shipment to a 340B covered entity
              location. It is not clear whether any covered entities are using this strategy yet, and one presenter
              cautioned that the strategy presents certain regulatory risks that must be considered carefully. This
              month, we will examine whether and how existing regulations may allow covered entities to engage in this
              type of redistribution.
            </p>
          </div>
        </article>
      </div>
    </OverlayLayout>
  );
};

export default RoundTableOverlay;
