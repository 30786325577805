import React, { useEffect } from "react";
import Layout from "../../Layout";
import { MembersProvider } from "./MembersContext";
import MemberToast from "./MemberToast";
import AdminHeader from "./AdminHeader";
import MemberSearch from "./MemberSearch";
import MemberView from "./MemberView";
import AdminView from "./AdminView";

const UsersIndex = ({ user, hasPendingActions, members }) => {
  useEffect(() => {
    scrollToFragment();
  }, []);

  const scrollToFragment = () => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <Layout user={{ ...user, hasPendingActions }}>
      <MembersProvider initialMembersState={{ members }}>
        <header className="users__header">
          <h1 className="fs--32 mb--24">Your Team</h1>
          {user.isAdmin && <AdminHeader />}
          <MemberSearch />
        </header>
        {user.isAdmin ? <AdminView /> : <MemberView />}
        <MemberToast />
      </MembersProvider>
    </Layout>
  );
};

export default UsersIndex;
