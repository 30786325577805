import React, { useContext, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import { UserContext } from "../../UserContext";
import PasswordEditForm from "./PasswordEditForm";
import NameEditForm from "./NameEditForm";
import MemberPasswordResetSuccess from "./dialogs/MemberPasswordResetConfirmation";
import Button from "../../elements/Button";
import Avatar from "../../elements/Avatar";
import Notice from "../../elements/Notice";

const ProfileMain = () => {
  const { currentUser } = useContext(UserContext);
  const [openForm, setOpenForm] = useState("");
  const [notice, setNotice] = useState({ kind: "error", open: false, message: "" });
  const [showMemberPasswordResetSuccess, setShowMemberPasswordResetSuccess] = useState(false);

  function handleCloseForm() {
    setOpenForm("");
  }

  return (
    <div className="profile display--grid gap--32 mt--44">
      <div className="bg--mid-gray-v2 br--6 pt--32 ph--32">
        <section className="pb--32 mb--32 border--b bc--white">
          <h2 className="fs--24 fw--300 mb--32">Basic Info</h2>
          <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input mb--32">
            <Avatar initials={currentUser.initials} color="blue" size="lg" />
            <div className="profile__truncate fs--16">
              <h3 className="fw--bold mb--8">
                {currentUser.firstName} {currentUser.lastName}
              </h3>
              <span>{currentUser.email}</span>
            </div>
          </div>
          <div className="display--flex flex--column">
            <span className="fs--12 fw--bold f--uppercase mb--16 light-gray">Name</span>
            <div className="display--flex flex--content-between flex--items-center gap--8">
              <span className="fw--300">
                {currentUser.firstName} {currentUser.lastName}
              </span>
              <Button variant="link" onClick={() => setOpenForm("NameEdit")}>
                Edit
              </Button>
            </div>
          </div>
        </section>
        <h2 className="fs--24 fw--300 mb--32">Security</h2>
        <div>
          <div className="display--flex flex--column mb--24">
            <span className="fs--12 fw--bold f--uppercase mb--16 light-gray">Password</span>
            <div className="display--flex flex--content-between flex--items-center gap--8">
              <span className="fw--300">********</span>
              <Button variant="link" onClick={() => setOpenForm("PasswordEdit")}>
                Edit
              </Button>
            </div>
          </div>
          <div className="display--flex flex--column">
            <span className="fs--12 fw--bold f--uppercase mb--16 light-gray">2-FA</span>
            <span className="fw--300 display--flex flex--items-center gap--16">
              <MdVerifiedUser size={20} color="#24b668" /> On
            </span>
          </div>
        </div>
      </div>
      {openForm === "NameEdit" && <NameEditForm handleCloseForm={handleCloseForm} setNotice={setNotice} />}
      {openForm === "PasswordEdit" && (
        <PasswordEditForm
          handleCloseForm={handleCloseForm}
          handleNotice={setNotice}
          handleSuccessDialog={setShowMemberPasswordResetSuccess}
        />
      )}
      <Notice details={notice} />
      <MemberPasswordResetSuccess isOpen={showMemberPasswordResetSuccess} />
    </div>
  );
};

export default ProfileMain;
