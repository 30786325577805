import React, { createContext, useContext, useState } from "react";
import { MdNorthEast, MdSouthEast, MdEast, MdCircle } from "react-icons/md";
import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  Sector,
  Cell,
  Bar,
  Line,
  Pie,
  BarChart as RechartBar,
  LineChart as RechartLine,
  PieChart as RechartPie,
} from "recharts";
import BaseCard from "./BaseCard";
import { Esp } from "../../Logos";
import { chartColors } from "../../utils/colorOptions";

const EspCardContext = createContext(null);

const useEspCardContext = () => {
  const context = useContext(EspCardContext);
  if (!context) {
    throw new Error("useEspCardContext must be used within an EspCardProvider");
  }
  const { data, meta } = context.data;
  return { data, meta };
};

function EspCard({ data, tag, children }) {
  return (
    <EspCardContext.Provider value={{ data }}>
      <BaseCard>
        <BaseCard.Header>
          <Badge />
          {tag}
        </BaseCard.Header>
        {children}
      </BaseCard>
    </EspCardContext.Provider>
  );
}

function Badge() {
  useEspCardContext();
  return (
    <BaseCard.BadgeContainer className="bg--badge">
      <Esp width={14} height={14} />
      <span>ESP</span>
    </BaseCard.BadgeContainer>
  );
}
function CurrencyTag() {
  const { meta } = useEspCardContext();
  if (!meta.change) return;

  const { direction, value, percentage } = meta.change;
  return (
    <BaseCard.TagContainer className="bg--tag light-gray">
      <TagIcon direction={direction} />
      <span>
        {value?.formatted.currency} ({percentage?.formatted})
      </span>
    </BaseCard.TagContainer>
  );
}

function NumberTag() {
  const { meta } = useEspCardContext();
  if (!meta.change) return;

  const { direction, value, percentage } = meta.change;
  return (
    <BaseCard.TagContainer className="bg--tag light-gray">
      <TagIcon direction={direction} />
      <span>
        {value?.formatted.number} ({percentage?.formatted})
      </span>
    </BaseCard.TagContainer>
  );
}

function TagIcon({ direction }) {
  useEspCardContext();
  if (!direction) return <MdEast color="#adbbc8" size={14} />;
  return direction === "+" ? <MdNorthEast color="#adbbc8" size={14} /> : <MdSouthEast color="#adbbc8" size={14} />;
}

function FeaturedDataPoint() {
  const { meta } = useEspCardContext();
  return <BaseCard.FeaturedText>{meta.featured_value}</BaseCard.FeaturedText>;
}

function Interval() {
  const { meta } = useEspCardContext();
  return <BaseCard.SecondaryText>{meta.interval}</BaseCard.SecondaryText>;
}

function formatLineChartData(chartData) {
  if (!chartData.length) return [{ name: null, value: 0 }];

  return chartData.map((datapoint) => ({ name: datapoint[0], value: datapoint[1] }));
}

function LineChart() {
  const { data } = useEspCardContext();

  return (
    <ResponsiveContainer height={60}>
      <RechartLine data={formatLineChartData(data)} margin={{ top: 8, bottom: 8, right: 4 }}>
        <Line type="natural" dataKey="value" stroke="#0059F5" strokeWidth={3} dot={false} />
      </RechartLine>
    </ResponsiveContainer>
  );
}

function BarChart() {
  const { data } = useEspCardContext();
  // NOTE - recharts comes with a Legend component, so may consider changing to that in the future.
  const legend = [
    {
      id: "conforming",
      label: "Conforming",
      color: "#33c197",
      radius: [2, 0, 0, 2],
    },
    {
      id: "non_conforming",
      label: "Non-Conforming",
      color: "#fec767",
      radius: [0, 2, 2, 0],
    },
  ];
  return (
    <div className="mt--16">
      <ResponsiveContainer height={20}>
        <RechartBar data={data} stackOffset="expand" layout="vertical" maxBarSize={8}>
          <XAxis type="number" hide />
          <YAxis type="category" dataKey="name" hide />
          {legend.map((item) => (
            <Bar key={item.id} dataKey={item.id} stackId="a" fill={item.color} radius={item.radius} />
          ))}
        </RechartBar>
      </ResponsiveContainer>
      <div className="display--flex gap--8 mt--8">
        {legend.map((item) => (
          <span className="fs--10 light-gray t--nowrap" key={item.label}>
            <MdCircle size={6} color={item.color} /> {item.label}
          </span>
        ))}
      </div>
    </div>
  );
}

function PieActiveShape(props) {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, label } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={4} textAnchor="middle" fill="#fff" fontWeight="bold">
        {label}
      </text>
      <text x={cx} y={cy} dy={20} textAnchor="middle" fill="#fff" fontSize={12}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 3}
        outerRadius={outerRadius + 3}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
}

function PieChart() {
  const { data } = useEspCardContext();
  const [activeChunk, setActiveChunk] = useState(0);

  if (!data.length) return null;

  return (
    <ResponsiveContainer height={148}>
      <RechartPie margin={{ top: 8, left: 0, right: 0, bottom: 16 }}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius="80%"
          outerRadius="100%"
          activeIndex={activeChunk}
          activeShape={<PieActiveShape />}
          onMouseEnter={(_, i) => setActiveChunk(i)}
        >
          {data.map((item, index) => (
            <Cell key={item.id} fill={chartColors[index] || "#fff"} stroke={null} />
          ))}
        </Pie>
      </RechartPie>
    </ResponsiveContainer>
  );
}

EspCard.BarChart = BarChart;
EspCard.LineChart = LineChart;
EspCard.PieChart = PieChart;
EspCard.FeaturedDataPoint = FeaturedDataPoint;
EspCard.Interval = Interval;
EspCard.CurrencyTag = CurrencyTag;
EspCard.NumberTag = NumberTag;
EspCard.Copy = BaseCard.CopyContainer;
EspCard.Section = BaseCard.Section;

export default EspCard;
