import React from "react";
import AppBadge from "./AppBadge";

const ErrorCard = ({ app, fs = "fs--16", ...props }) => {
  return (
    <div className="bg--mid-gray-v2 hover hover--card br--4 pa--24 white tile">
      <AppBadge app={app} fill="blue" />
      <p className={`t--subtitle mb--12 mt--24 lh--tight ${fs}`}>{props.children}</p>
    </div>
  );
};

export default ErrorCard;
