import React from "react";
import { MdCircle, MdHourglassEmpty, MdSupervisorAccount, MdLockOutline } from "react-icons/md";
import { useMembers } from "./MembersContext";
import Avatar from "../../elements/Avatar";
import Tag from "../../elements/Tag";

function AvatarGroup({ members }) {
  const firstThreeMembers = members.slice(0, 3);
  const remaining = members.slice(3).length;
  return (
    <div className="avatar-group display--grid flex--items-center ml--auto">
      {firstThreeMembers.map((member) => (
        <Avatar key={member.id} initials={member.initials} size="sm" />
      ))}
      {remaining > 0 && <span className="ml--8 fs--10">+{remaining}</span>}
    </div>
  );
}

function PendingActionsTag({ count }) {
  if (!count || count === 0) return;
  return (
    <Tag>
      <MdCircle size={8} color="#e91e63" /> {count} {count === 1 ? "Action Needed" : "Actions Needed"}
    </Tag>
  );
}

function MemberCard({ icon, title, count, children }) {
  return (
    <div className="display--flex flex--column ph--24 pv--16 bg--mid-gray-v2 br--4">
      {icon}
      <h2 className="mt--4 lh--solid fs--16">{title}</h2>
      <div className="display--flex flex--items-center gap--16 mt--16">
        <span className="fs--32 fw--500 lh--solid">{count}</span>
        {children}
      </div>
    </div>
  );
}

export default function AdminHeader() {
  const { byStatus } = useMembers();
  const { active, locked, pending } = byStatus;

  return (
    <div className="display--flex gap--32 mb--32">
      <a className="users__card white" href="#active">
        <MemberCard icon={<MdSupervisorAccount />} title="Current Members" count={active.length}>
          {active.length > 0 && <AvatarGroup members={active} />}
        </MemberCard>
      </a>
      <a className="users__card white" href="#locked">
        <MemberCard icon={<MdLockOutline />} title="Locked Members" count={locked.length}>
          <PendingActionsTag count={locked["password_reset_pending_approval"]?.length} />
        </MemberCard>
      </a>
      <a className="users__card white" href="#pending">
        <MemberCard icon={<MdHourglassEmpty />} title="Pending Invites" count={pending.length}>
          <PendingActionsTag count={pending["registered"]?.length} />
        </MemberCard>
      </a>
    </div>
  );
}
