import React from "react";
import { MdSearch } from "react-icons/md";

const SearchInput = ({ placeholder = "Search", onChange }) => {
  return (
    <div className="display--flex flex--items-center flex--item-grow1 gap--12 border br--6 pv--8 ph--12 w--max-360px border--none bg--mid-gray-v2 focus--bc-white">
      <MdSearch color="#ffffff" />
      <input
        className="white border--none bg--none w--100 fs--16"
        type="text"
        id="search"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
