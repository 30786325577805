import React from "react";
import useFetch from "../../hooks/useFetch";
import EspCard from "./EspCard";
import LoadingCard from "../../elements/LoadingCard";
import EspErrorCard from "./EspErrorCard";

export default function EntitiesWithPricingAccess() {
  const { isLoading, data, error } = useFetch("entities-with-pricing-access");

  if (isLoading) return <LoadingCard />;

  if (error)
    return (
      <EspErrorCard error={error}>
        <EspErrorCard.PieChart />
        <EspErrorCard.ChartCopy />
      </EspErrorCard>
    );

  return (
    <EspCard data={data} tag={<EspCard.NumberTag />}>
      <EspCard.Section>
        <EspCard.PieChart />
        <EspCard.Copy>
          <EspCard.FeaturedDataPoint /> <span>CEs with access to 340B pricing</span>
        </EspCard.Copy>
        <EspCard.Interval />
      </EspCard.Section>
    </EspCard>
  );
}
