import React, { createContext, useContext } from "react";

const BaseCardContext = createContext(false);

const useBaseCardContext = () => {
  const context = useContext(BaseCardContext);
  if (!context) {
    throw new Error("useBaseCardContext must be used within a BaseCardProvider");
  }
  return context;
};

function BaseCard({ children }) {
  return (
    <BaseCardContext.Provider value={true}>
      <article className="basecard position--rel bg--mid-gray-v2 hover hover--card white br--12 pb--24">
        {children}
      </article>
    </BaseCardContext.Provider>
  );
}

function Header({ children }) {
  useBaseCardContext();
  return <header className="display--flex flex--content-between mt--24">{children}</header>;
}

function Section({ children }) {
  useBaseCardContext();
  return <section className="mt--16">{children}</section>;
}

function FullSection({ children }) {
  useBaseCardContext();
  return <section className="basecard--full">{children}</section>;
}

function BadgeContainer({ children, className = "" }) {
  useBaseCardContext();
  return (
    <div className={`display--flex flex--items-center gap--8 pv--4 ph--8 br--6 fs--12 ${className}`}>{children}</div>
  );
}

function TagContainer({ children, className = "" }) {
  useBaseCardContext();
  return (
    <span className={`display--inline-flex flex--items-center gap--4 pa--4 fs--12 t--nowrap ${className}`}>
      {children}
    </span>
  );
}

function CopyContainer({ children }) {
  useBaseCardContext();
  return <p className="mb--8 lh--tight">{children}</p>;
}

function FeaturedText({ children }) {
  useBaseCardContext();
  return <strong className="fs--24 fw--bold">{children}</strong>;
}

function SecondaryText({ children }) {
  useBaseCardContext();
  return <p className="basecard--secondary-text fs--12 light-gray">{children}</p>;
}

BaseCard.Header = Header;
BaseCard.Section = Section;
BaseCard.FullSection = FullSection;
BaseCard.BadgeContainer = BadgeContainer;
BaseCard.TagContainer = TagContainer;
BaseCard.CopyContainer = CopyContainer;
BaseCard.FeaturedText = FeaturedText;
BaseCard.SecondaryText = SecondaryText;

export default BaseCard;
