import React from "react";
import Layout from "../../Layout";
import Header from "../landing/Header";
import ProfileMain from "./ProfileMain";

const UserProfileIndex = (props) => {
  return (
    <Layout user={{ ...props.user, hasPendingActions: props.hasPendingActions }}>
      <Header mfr={props.mfr} />
      <ProfileMain />
    </Layout>
  );
};

export default UserProfileIndex;
