import React from "react";

const Layout = ({ title = "Page Title", children }) => {
  return (
    <div className="authentication">
      <section className="authentication__left">LOGO</section>
      <section className="authentication__right">
        <div className="authentication__form">
          <div className="authentication__title">{title}</div>
          {children}
        </div>
      </section>
    </div>
  );
};

export default Layout;
