import React from "react";
import Dialog from "../../../elements/Dialog";

const MemberPasswordResetSuccess = ({ isOpen }) => {
  if (isOpen) {
    setTimeout(() => window.location.replace("/logout"), 5000);
  }

  return (
    <Dialog
      open={isOpen}
      title="Success!"
      description="Your new password has been set. Your account will be locked until your administrator approves the request. You will now be redirected to the MOSAIC homepage."
    />
  );
};

export default MemberPasswordResetSuccess;
