import React, { useContext, useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { UserContext } from "../../UserContext";
import Button from "../../elements/Button";
import MemberPasswordReset from "./dialogs/MemberPasswordReset";

const PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const FORM_FIELDS = [
  {
    label: "Current password",
    id: "current-password",
    name: "current",
  },
  {
    label: "New password",
    id: "new-password",
    name: "new",
  },
  {
    label: "Confirm password",
    id: "confirm-password",
    name: "confirmNew",
  },
];
const NEW_PASS_ERROR =
  "Make sure passwords match and contain 8 or more characters with at least one number, one lower case letter, one upper case letter and one symbol.";

const PasswordEditForm = ({ handleCloseForm, handleNotice, handleSuccessDialog }) => {
  const { currentUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirmNew: false,
  });
  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirmNew: "",
  });
  const [passwordError, setPasswordError] = useState({
    current: "",
    new: "",
    confirmNew: "",
  });

  const isSaveDisabled = Object.values(password).some((val) => val === "");

  async function handleEditPassword() {
    // Avoids request if passwords don't match or doesn't pass requirement.
    if (password.new !== password.confirmNew || !PASS_REGEX.test(password.new)) {
      setPasswordError({ ...passwordError, new: NEW_PASS_ERROR, confirmNew: NEW_PASS_ERROR });
      return;
    }

    const requestOptions = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(password),
    };

    try {
      const response = await fetch(`/organization/${currentUser.organizationId}/update_password`, requestOptions);
      const json = await response.json();

      if (!response.ok) throw new Error(json.error);

      handlePasswordSuccess(json.notice);
      handleCloseForm();
    } catch (error) {
      setPasswordError({ ...passwordError, current: error.message });
    }
  }

  function handleShowPassword(pass) {
    setShowPassword({ ...showPassword, [pass]: !showPassword[pass] });
  }

  function handleInputChange(e) {
    setPassword({ ...password, [e.target.name]: e.target.value });
    setPasswordError({ ...passwordError, current: "", new: "", confirmNew: "" });
  }

  function handleAdminPasswordSave(e) {
    e.preventDefault();
    handleEditPassword();
  }

  // For member flow we display a dialog on success that logs the user out after 5 seconds.
  function handlePasswordSuccess(message) {
    if (currentUser.isAdmin) {
      return handleNotice({ kind: "success", open: true, message });
    }
    return handleSuccessDialog(true);
  }

  function renderSave() {
    if (currentUser.isAdmin) {
      return (
        <Button utilityClasses="ph--16 br--pill lh--tight" type="submit" isDisabled={isSaveDisabled}>
          Save Changes
        </Button>
      );
    }

    return <MemberPasswordReset handleEditPassword={handleEditPassword} isDisabled={isSaveDisabled} />;
  }

  return (
    <div className="profile__animate-in pt--32">
      <h2 className="fs--24 fw--300 mb--32">Password</h2>
      <form onSubmit={handleAdminPasswordSave}>
        {FORM_FIELDS.map(({ label, id, name }) => (
          <div className="display--flex flex--column gap--8 mb--32" key={id}>
            <label htmlFor={id} className="fs--12 f--uppercase">
              {label}
            </label>
            <div className="position--rel">
              <input
                id={id}
                name={name}
                autoComplete="on"
                className="white bg--dialog-input border--none pa--16 br--8 w--100"
                type={showPassword[name] ? "text" : "password"}
                onChange={handleInputChange}
                autoFocus={name === "current"}
                required
              />
              {!!password[name] && (
                <Button
                  onClick={() => handleShowPassword(name)}
                  variant="tertiary"
                  utilityClasses="position--abs top--0 bottom--0 right--16 bg--none"
                >
                  {showPassword[name] ? <MdOutlineVisibility size={24} /> : <MdOutlineVisibilityOff size={24} />}
                </Button>
              )}
            </div>
            {!!passwordError[name] && <span className="fs--14 red">{passwordError[name]}</span>}
          </div>
        ))}
        <div className="display--flex flex--content-end gap--16">
          {renderSave()}
          <Button variant="secondary" utilityClasses="ph--12 br--pill lh--tight" onClick={handleCloseForm}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordEditForm;
