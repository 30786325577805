import React, { useState } from "react";
import Nav from "./Nav";
import { UserContext } from "./UserContext";

export default function Layout({ children, user, orgId }) {
  const [currentUser, setCurrentUser] = useState(user);
  const value = { currentUser, setCurrentUser };

  return (
    <UserContext.Provider value={value}>
      <div className="layout white">
        <div className="layout__wrapper">
          <Nav />
          <main className="layout__main">{children}</main>
        </div>
      </div>
    </UserContext.Provider>
  );
}
