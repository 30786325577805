import React from "react";
import Member from "../../elements/Member";
import MemberHeading from "../../elements/MemberHeading";
import RevokeInvitation from "./dialogs/RevokeInvitation";
import ConfirmUser from "./dialogs/ConfirmUser";
import ResendInvite from "./dialogs/ResendInvite";
import Tag from "../../elements/Tag";
import { MdCircle } from "react-icons/md";
import { useMembers } from "./MembersContext";

const PendingMembers = () => {
  const {
    byStatus: { pending },
  } = useMembers();

  const registeredMembers = pending.filter((member) => member.activation_state === "registered");
  const pendingRegistration = pending.filter((member) => member.activation_state === "pending");

  const renderRegisteredMembers = () => {
    return registeredMembers.map((member) => {
      return (
        <Member key={member.id} member={member} showExpiration actionRequired>
          <ConfirmUser member={member} />
          <RevokeInvitation member={member} />
        </Member>
      );
    });
  };

  const renderInvitedMembers = () => {
    return pendingRegistration
      .sort((a, b) => new Date(b.invite_expires_at).getTime() - new Date(a.invite_expires_at).getTime())
      .map((member) => {
        return (
          <Member key={member.id} member={member} showExpiration>
            <ResendInvite member={member} />
            <RevokeInvitation member={member} />
          </Member>
        );
      });
  };

  function renderMembersList() {
    if (pending.length) {
      return (
        <div className="display--flex flex--column gap--16">
          <MemberHeading headings={["Name", "Product Access", "Expiration Date"]} />
          {renderRegisteredMembers()}
          {renderInvitedMembers()}
        </div>
      );
    }

    return (
      <div className="display--flex flex--center gap--16 border--dashed bc--disabled br--8 bg--mid-gray-v3">
        <div className="t--disabled">No pending members</div>
      </div>
    );
  }

  const renderPendingActionsTag = () => {
    const count = pending.filter((member) => member.activation_state === "registered").length;

    if (count <= 0) return;

    const text = count === 1 ? "Action Needed" : "Actions Needed";

    return (
      <Tag>
        <MdCircle size={8} color="#e91e63" /> {count} {text}
      </Tag>
    );
  };

  return (
    <section id="pending" className="users__section border--t bc--white pb--32">
      <div>
        <div className="display--flex flex--items-center flex--content-between mb--32">
          <h2 className="fs--24 fw--300 lh--tight">Pending Invites</h2>
          {renderPendingActionsTag()}
        </div>
        <p className="fs--12 lh--tight mb--24 w--max-35ch">
          Members who have been invited to MOSAIC and have either not accepted their invite or are awaiting admin
          approval.
        </p>
      </div>
      {renderMembersList()}
    </section>
  );
};

export default PendingMembers;
