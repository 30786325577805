import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { MdClose } from "react-icons/md";

const Dialog = ({
  isFullHeight,
  trigger,
  title,
  description,
  confirm,
  cancel,
  hasButtonsStacked,
  children,
  open,
  setOpen,
}) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen}>
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <RadixDialog.Portal className="dialog">
        <RadixDialog.Overlay className="dialog__overlay position--fix bg--dialog-overlay" />
        <RadixDialog.Content
          className={`
            ${isFullHeight ? "dialog__content dialog__content--scroll" : "dialog__content"} 
            display--flex flex--column position--fix br--8 bg--black white z--100
          `}
        >
          <RadixDialog.Close className="dialog__close position--fix">
            <MdClose />
          </RadixDialog.Close>
          <RadixDialog.Title className="fs--24 fw--300">{title}</RadixDialog.Title>
          <RadixDialog.Description className={`fs--14 mt--24 ${children ? "mb--0" : "mb--40"}`}>
            {description}
          </RadixDialog.Description>
          {children && <div className="mt--40 mb--48">{children}</div>}
          <div className={`${hasButtonsStacked ? "display--flex flex--column" : "display--flex"} gap--20 mt--auto`}>
            <RadixDialog.Close asChild>{confirm}</RadixDialog.Close>
            <RadixDialog.Close asChild>{cancel}</RadixDialog.Close>
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export default Dialog;
