import React from "react";
import ActiveMembers from "./ActiveMembers";
import LockedMembers from "./LockedMembers";
import PendingMembers from "./PendingMembers";

export default function AdminView() {
  return (
    <>
      <ActiveMembers />
      <LockedMembers />
      <PendingMembers />
    </>
  );
}
