import React, { useContext } from "react";
import { MdDeleteOutline } from "react-icons/md";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";
import { deleteData } from "../../../utils/api";
import { UserContext } from "../../../UserContext";
import { useMembers, useMembersToast } from "../MembersContext";

const RevokeInvitation = ({ member }) => {
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();

  async function revokeInvitation() {
    try {
      const response = await deleteData(`/${currentUser.organizationId}/users/${member.id}`);
      dispatch({ type: "revoke_invitation", payload: { member } });
      dispatchToast({ type: "success_toast", payload: response.notice || "Request successfully deleted" });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  return (
    <Dialog
      trigger={
        <Button variant="tertiary" utilityClasses="br--6 pa--8" data-cy="delete-user-button">
          <MdDeleteOutline size={20} />
        </Button>
      }
      title="Revoke Invitation"
      description="Please confirm that you want to revoke the invitation for this user to join Mosaic."
      confirm={
        <Button
          onClick={revokeInvitation}
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
          data-cy="dialog__delete-user-button"
        >
          Confirm
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Cancel
        </Button>
      }
    >
      <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input">
        <Avatar initials={member.initials} size="lg" />
        <div>
          <h3 className="fs--18 fw--500">{member.name}</h3>
          <span className="fs--12">{member.email}</span>
        </div>
      </div>
    </Dialog>
  );
};

export default RevokeInvitation;
