import React from "react";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";

const PasswordReset = ({ member, approvePasswordReset }) => {
  return (
    <Dialog
      trigger={
        <Button variant="secondary" utilityClasses="br--pill ph--12">
          Approve Request
        </Button>
      }
      title="Password Reset"
      description="A link to reset password will be sent to"
      confirm={
        <Button
          onClick={() => approvePasswordReset(member, "Password reset successfully approved")}
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
        >
          Send
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Cancel
        </Button>
      }
    >
      <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input">
        <Avatar initials={member.initials} size="lg" />
        <div>
          <h3 className="fs--18 fw--500">{member.name}</h3>
          <span className="fs--12">{member.email}</span>
        </div>
      </div>
    </Dialog>
  );
};

export default PasswordReset;
