import { useEffect, useState, useContext } from "react";
import { UserContext } from "../UserContext";
import { getData } from "../utils/api";

export default function useFetch(endpoint) {
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isFetching = true;

    async function makeRequest() {
      try {
        const data = await getData(`/${currentUser.organizationId}/${endpoint}`);
        if (isFetching) setData(data);
      } catch (error) {
        if (isFetching) setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    makeRequest();

    return () => {
      isFetching = false;
    };
  }, [endpoint]);

  return { isLoading, data, error };
}
