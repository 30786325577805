import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Billboard from "../Billboard";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const SignUp = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);
  const [confirmPasswordIsMasked, setConfirmPasswordIsMasked] = useState(true);

  const hasAtLeaseOneLower = (val) => {
    return val.match(/.*[a-z].*/) != null;
  };

  const hasAtLeaseOneUpper = (val) => {
    return val.match(/.*[A-Z].*/) != null;
  };

  const hasAtLeaseOneNumber = (val) => {
    return val.match(/.*[0-9].*/) != null;
  };

  const hasAtLeaseOneSpecial = (val) => {
    return val.match(/.*[\W_].*/) != null;
  };

  const hasAtLeaseEightChars = (val) => {
    return val.length >= 8;
  };

  const hasMatchingPasswords = () => {
    if (!password || !confirmPassword) {
      return false;
    }
    return password === confirmPassword;
  };

  const isValidPassword = (val) => {
    if (val) {
      return (
        hasAtLeaseOneLower(val) &&
        hasAtLeaseOneUpper(val) &&
        hasAtLeaseOneNumber(val) &&
        hasAtLeaseOneSpecial(val) &&
        hasAtLeaseEightChars(val)
      );
    }
  };

  const enableSubmit = () => {
    return isValidPassword(password) && isValidPassword(confirmPassword) && hasMatchingPasswords();
  };

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 100, marginBottom: 50 }} />

        <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Welcome</h1>
        <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
          Activate your account below
        </p>

        <form method="POST" action={`/setpassword?token=${props.token}`} className="authentication__form">
          <div className="">
            <input type="hidden" id="token" name="session[token]" value={props.token} />
            <CustomTextField
              label="Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="session[email]"
              fullWidth
              required
              value={props.email}
              disabled
            />
            <CustomTextField
              label="Password"
              margin="normal"
              variant="outlined"
              id="password"
              fullWidth
              required
              autoFocus
              name="session[password]"
              type={passwordIsMasked ? "password" : "text"}
              error={password ? !isValidPassword(password) : false}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ cursor: "pointer" }} onClick={() => setPasswordIsMasked(!passwordIsMasked)}>
                      <span
                        style={{
                          color: "#718291",
                          fontSize: 14,
                          marginRight: 10,
                          verticalAlign: "top",
                          lineHeight: "20px",
                        }}
                      >
                        {passwordIsMasked ? "show" : "hide"}
                      </span>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              label="Confirm Password"
              margin="normal"
              variant="outlined"
              id="confirm_password"
              fullWidth
              required
              name="session[confirm_password]"
              type={confirmPasswordIsMasked ? "password" : "text"}
              error={confirmPassword ? !isValidPassword(confirmPassword) && !hasMatchingPasswords() : false}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setConfirmPasswordIsMasked(!confirmPasswordIsMasked)}
                    >
                      <span
                        style={{
                          color: "#718291",
                          fontSize: 14,
                          marginRight: 10,
                          verticalAlign: "top",
                          lineHeight: "20px",
                        }}
                      >
                        {confirmPasswordIsMasked ? "show" : "hide"}
                      </span>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <div className="authentication__form__requirements">
              <ul>
                <li className={hasAtLeaseOneLower(password) ? "success" : null}>One lowercase character</li>
                <li className={hasAtLeaseOneUpper(password) ? "success" : null}>One uppercase character</li>
                <li className={hasAtLeaseOneNumber(password) ? "success" : null}>One number</li>
                <li className={hasAtLeaseOneSpecial(password) ? "success" : null}>One special character</li>
                <li className={hasAtLeaseEightChars(password) ? "success" : null}>8 characters minimum</li>
                <li className={hasMatchingPasswords() ? "success" : null}>Matching passwords</li>
              </ul>
            </div>
          </div>
          <div className="auth__form__footer">
            <button
              className="btn"
              style={{ flexGrow: 1, alignSelf: "inherit" }}
              type="submit"
              disabled={!enableSubmit()}
            >
              REGISTER
            </button>

            {/* <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div> */}
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <Billboard />
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default SignUp;
