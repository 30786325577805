import React from "react";

const MemberHeading = ({ headings = [] }) => {
  return (
    <div className="member display--grid gap--32 ph--24">
      {headings.map((heading, i) => {
        return (
          <span className="fs--12 f--uppercase" key={`${heading}-${i}`}>
            {heading}
          </span>
        );
      })}
    </div>
  );
};

export default MemberHeading;
