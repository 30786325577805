import React, { useContext } from "react";
import SearchInput from "../../elements/SearchInput";
import InviteNewUser from "./dialogs/InviteNewUser";
import { useMembersSearch } from "./MembersContext";
import { UserContext } from "../../UserContext";

export default function MemberSearch() {
  const { currentUser } = useContext(UserContext);
  const { searchQuery, setSearchQuery, isSearchResultEmpty } = useMembersSearch();

  return (
    <section>
      <div className="display--flex flex--content-between">
        <SearchInput placeholder="Search for User" onChange={(e) => setSearchQuery(e.target.value)} />
        {currentUser.isAdmin && <InviteNewUser />}
      </div>
      <div className="pt--8 fs--14 red h--min-36px">
        {isSearchResultEmpty && `No results found for "${searchQuery}"`}
      </div>
    </section>
  );
}
