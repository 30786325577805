import React, { useState } from "react";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";

const MemberPasswordReset = ({ isDisabled, handleEditPassword }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      open={isOpen}
      setOpen={setIsOpen}
      trigger={
        <Button isDisabled={isDisabled} utilityClasses="ph--16 br--pill lh--tight">
          Save Changes
        </Button>
      }
      title="Password Reset"
      description="Are you sure you want to reset your password? You will be locked out of your account and lose access to all apps until your request is approved by your administrator."
      confirm={
        <Button onClick={handleEditPassword} utilityClasses="ph--8 br--pill lh--tight flex--item-grow1">
          Yes, Reset Password
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Cancel
        </Button>
      }
    />
  );
};

export default MemberPasswordReset;
