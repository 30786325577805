import mixpanel from "mixpanel-browser";

const prodToken = "5ee498e8f1ed26a28fcd1d66ab5a1f5b";
const devToken = "cda51abddce52a2d7667df3a49720805";

const projectToken = import.meta.env.PROD ? prodToken : devToken;

mixpanel.init(projectToken, {
  // To test in dev environment and send events to Mixpanel, set the variable 'sendDevEventsToMixpanel' in `mixpanel_service.rb` to true. To log events to the console, set the value of 'debug' below to true.
  debug: false,
  api_host: `${window.location.origin}/event`,
  ignore_dnt: true,
  xhr_headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
  },
  track_pageview: true,
  batch_requests: false,
});
