import React from "react";
import useFetch from "../../hooks/useFetch";
import EspCard from "./EspCard";
import LoadingCard from "../../elements/LoadingCard";
import EspErrorCard from "./EspErrorCard";

export default function ChargebackSales() {
  const { isLoading, data, error } = useFetch("chargeback-sales");

  if (isLoading) return <LoadingCard />;

  if (error) {
    return (
      <EspErrorCard error={error}>
        <EspErrorCard.LineChart />
        <EspErrorCard.ChartCopy />
      </EspErrorCard>
    );
  }

  return (
    <EspCard data={data} tag={<EspCard.CurrencyTag />}>
      <EspCard.Section>
        <EspCard.LineChart />
        <EspCard.Copy>
          <EspCard.FeaturedDataPoint /> <span>WAC 340B Chargebacks</span>
        </EspCard.Copy>
        <EspCard.Interval />
      </EspCard.Section>
    </EspCard>
  );
}
