import React from "react";
import useFetch from "../../hooks/useFetch";
import LoadingCard from "../../elements/LoadingCard";
import EspCard from "./EspCard";
import EspErrorCard from "./EspErrorCard";

export default function ClaimsByCategory() {
  const { isLoading, data, error } = useFetch("claims-by-category");

  if (isLoading) return <LoadingCard />;

  if (error) {
    return (
      <EspErrorCard error={error}>
        <EspErrorCard.ChartCopy />
        <EspErrorCard.BarChart />
      </EspErrorCard>
    );
  }

  return (
    <EspCard data={data} tag={<EspCard.NumberTag />}>
      <EspCard.Section>
        <EspCard.Copy>
          <EspCard.FeaturedDataPoint /> <span>Claims submitted that are non-conforming.</span>
        </EspCard.Copy>
        <EspCard.Interval />
      </EspCard.Section>
      <EspCard.BarChart />
    </EspCard>
  );
}
