import React from "react";
import { Esp } from "../../Logos";
import Tooltip from "../../elements/Tooltip";
import BaseCard from "./BaseCard";
import mfrStateCPExemptionSummary from "../../utils/mfrStateCPExemptionSummary";

export default function StateExemptions({ cpExemptionStates }) {
  const filteredStates = mfrStateCPExemptionSummary.filter(({ state }) =>
    cpExemptionStates.includes(state)
  );
  const firstThreeStates = filteredStates.slice(0, 3);
  const remainingStates = filteredStates.slice(3);

  return (
    <BaseCard>
      <BaseCard.Header>
        <BaseCard.BadgeContainer className="bg--badge">
          <Esp width={14} height={14} />
          <span>ESP</span>
        </BaseCard.BadgeContainer>
      </BaseCard.Header>
      <BaseCard.Section>State Exemption Tracker</BaseCard.Section>
      <BaseCard.Section>
        <section className="display--flex flex--column pa--12 bg--elevation-2 border br--8 bc--primary">
          {firstThreeStates.map(({ state, adherence }) => (
            <div
              className="display--flex flex--items-center gap--8"
              key={state}
            >
              <img height={16} src={`/assets/${state}.svg`} alt="map icon" />
              <span>
                {adherence.withClaims + adherence.withoutClaims} MFRs in {state}
              </span>
            </div>
          ))}
          {remainingStates.length > 0 && (
            <Tooltip
              trigger={
                <span className="fs--12 light-gray hover--text">
                  +{remainingStates.length} More States
                </span>
              }
            >
              <div className="display--flex flex--column pa--8 br--8 bg--gray-v2">
                {remainingStates.map(({ state, adherence }) => (
                  <span className="fs--12">
                    {adherence.withClaims + adherence.withoutClaims} MFRs in {state}
                  </span>
                ))}
              </div>
            </Tooltip>
          )}
        </section>
      </BaseCard.Section>
    </BaseCard>
  );
}
