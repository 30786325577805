const mfrStateCPExemptionSummary = [
  {
    state: "AR",
    adherence: {
      withClaims: 5,
      withoutClaims: 23
    }
  },
  {
    state: "LA",
    adherence: {
      withClaims: 3,
      withoutClaims: 9
    }
  },
  {
    state: "WV",
    adherence: {
      withClaims: 0,
      withoutClaims: 11
    }
  },
  {
    state: "MD",
    adherence: {
      withClaims: 3,
      withoutClaims: 8
    }
  },
  {
    state: "MS",
    adherence: {
      withClaims: 3,
      withoutClaims: 8
    }
  },
  {
    state: "KS",
    adherence: {
      withClaims: 3,
      withoutClaims: 7
    }
  },
  {
    state: "MN",
    adherence: {
      withClaims: 2,
      withoutClaims: 5
    }
  },
]

export default mfrStateCPExemptionSummary
