import React from "react";
import { MdLock } from "react-icons/md";
import AppBadge from "./AppBadge";

const AppCard = ({ appId, appName, appUrl, hasAccess, children, ...rest }) => {
  function renderButton() {
    if (!hasAccess) {
      return (
        <a href="/" className="white fs--12" data-cy={`apps__${appId}-subscribe-button`}>
          Subscribe for Access
        </a>
      );
    }

    return (
      <a href={appUrl} data-cy={`apps__${appId}-launch-button`}>
        <span className="button button--primary display--flex flex--center black bg--white br--pill fs--12 pv--8 ph--12">
          Launch {appName}
        </span>
      </a>
    );
  }

  return (
    <div
      className={`${
        hasAccess ? "bg--mid-gray-v2" : "bg--dialog-input white border bc--disabled"
      } br--6 pa--24 display--flex flex--column`}
      {...rest}
    >
      <header className="display--flex flex--content-between flex--items-center mb--24">
        <AppBadge app={appId} size={32} fill="blue" /> {!hasAccess && <MdLock size={24} />}
      </header>
      <div className="fs--12 lh--copy mb--24">{children}</div>
      <footer className="mt--auto ml--auto">{renderButton()}</footer>
    </div>
  );
};

export default AppCard;
