import React, { useContext } from "react";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";
import { postData } from "../../../utils/api";
import { UserContext } from "../../../UserContext";
import { useMembers, useMembersToast } from "../MembersContext";

const ConfirmUser = ({ member }) => {
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();

  async function approveRegistration() {
    try {
      const response = await postData(`/${currentUser.organizationId}/approve_registration`, { id: member.id });
      // Using member object from the UI, not the API response because it returns all users.
      dispatch({ type: "approve_registration", payload: { member } });
      dispatchToast({ type: "success_toast", payload: response.notice || "User successfully approved" });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  return (
    <Dialog
      trigger={
        <Button variant="secondary" utilityClasses="br--pill ph--12">
          Confirm
        </Button>
      }
      title="Confirm User"
      description="Please confirm that this is a legitimate request before approving."
      confirm={
        <Button
          utilityClasses="ph--8 br--pill lh--tight"
          onClick={approveRegistration}
          data-cy="confirm-registration-modal-button"
        >
          Confirm
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Revoke Invitation
        </Button>
      }
      hasButtonsStacked
    >
      <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input">
        <Avatar initials={member.initials} size="lg" />
        <div>
          <h3 className="fs--18 fw--500">{member.name}</h3>
          <span className="fs--12">{member.email}</span>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmUser;
