import React from "react";

import MosaicLogo from "~/images/mosaic_logo.png";
import Illustration from "~/images/illustration.png";

const Billboard = () => {
  return (
    <div className="auth__container--billboard">
      <div className="auth__container--billboard__title">
        <div className="auth__container--billboard__logo">
          <img src={MosaicLogo} />
        </div>

        <img src={Illustration} style={{ maxWidth: 450 }} />

        <div style={{ marginTop: 30 }}>
          <span className="t--xl t--500">MOSAIC™</span>
          <br />
          <span className="t--lg">340B Channel Management</span>
          <p className="t--md color--subtitle" style={{ marginTop: 20, maxWidth: 380 }}>
            MOSAIC enables effective 340B channel management by assembling BRG’s suite of 340B solutions into a single
            point of access. Research & analytics, CP policy operations and duplicate discount solutions: 340B channel
            management begins with MOSAIC.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Billboard;
