import React from "react";

const Tag = ({ children, utilityClasses = "bg--gray white" }) => {
  return (
    <span className={`display--inline-flex flex--items-center gap--4 ph--4 br--6 fs--12 t--nowrap ${utilityClasses}`}>
      {children}
    </span>
  );
};

export default Tag;
