import React, { createContext, useContext, useReducer, useState } from "react";

export const MembersContext = createContext(null);
export const MembersToastContext = createContext(null);
export const MembersSearchContext = createContext(null);

export function MembersProvider({ initialMembersState, children }) {
  const { members: initialMembers } = initialMembersState;
  const [members, dispatch] = useReducer(membersReducer, initialMembers);
  const [toast, dispatchToast] = useReducer(toastReducer, {
    show: false,
    message: null,
    kind: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const byStatus = membersByStatus(members, searchQuery);
  const isSearchResultEmpty =
    !!searchQuery && !byStatus.active.length && !byStatus.locked.length && !byStatus.pending.length;

  return (
    <MembersContext.Provider value={{ byStatus, dispatch }}>
      <MembersToastContext.Provider value={{ toast, dispatchToast }}>
        <MembersSearchContext.Provider value={{ searchQuery, setSearchQuery, isSearchResultEmpty }}>
          {children}
        </MembersSearchContext.Provider>
      </MembersToastContext.Provider>
    </MembersContext.Provider>
  );
}

export function useMembers() {
  return useContext(MembersContext);
}

export function useMembersToast() {
  return useContext(MembersToastContext);
}

export function useMembersSearch() {
  return useContext(MembersSearchContext);
}

function membersReducer(members, { type, payload }) {
  switch (type) {
    case "invite": {
      return [...members, payload.member];
    }
    case "resend_invite":
    case "approve_registration":
    case "edit_permissions":
    case "reset_password":
    case "resend_reset_password":
    case "approve_password_reset": {
      return updateMember({ members, member: payload.member });
    }
    case "delete_member":
    case "delete_locked_member":
    case "revoke_invitation": {
      return removeMember({ members, member: payload.member });
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

function toastReducer(toast, { type, payload }) {
  switch (type) {
    case "close_toast": {
      return { ...toast, isOpen: false };
    }
    case "error_toast": {
      return { ...toast, isOpen: true, kind: "error", message: payload || "An error occurred." };
    }
    case "success_toast": {
      return { ...toast, isOpen: true, kind: "success", message: payload || "Success!" };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

function membersByStatus(members, searchQuery) {
  function memberSearch() {
    if (searchQuery) {
      return members.filter((member) => member.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    return members;
  }
  function memberStatus(statusArr) {
    return memberSearch().filter((member) => statusArr.includes(member.activation_state));
  }

  return {
    active: memberStatus(["active"]),
    locked: memberStatus(["password_reset_pending_approval", "password_reset_required"]),
    pending: memberStatus(["pending", "registered"]),
  };
}

function updateMember({ members, member }) {
  return members.map((m) => (m.id === member.id ? member : m));
}

function removeMember({ members, member }) {
  return members.filter((m) => m.id !== member.id);
}
