import React, { useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import Button from "../../elements/Button";

const NameEditForm = ({ handleCloseForm, setNotice }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [newName, setNewName] = useState({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
  });

  const isNameEmpty = Object.values(newName).some((val) => val === "" || !val.trim().length);
  const isSaveDisabled =
    (currentUser.firstName === newName.firstName && currentUser.lastName === newName.lastName) || isNameEmpty;

  async function handleEditUser(e) {
    e.preventDefault();

    const requestOptions = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(newName),
    };

    try {
      const response = await fetch(`/organization/${currentUser.organizationId}/update_name`, requestOptions);
      const json = await response.json();

      if (!response.ok) throw new Error(json.error);

      setCurrentUser(json.user);
      setNotice({ kind: "success", open: true, message: json.notice });
    } catch (error) {
      setNotice({ kind: "error", open: true, message: error.message });
    } finally {
      handleCloseForm();
    }
  }

  function handleInputChange(e) {
    setNewName({ ...newName, [e.target.name]: e.target.value });
  }

  function handleFocus(e) {
    e.target.select();
  }

  return (
    <div className="profile__animate-in pt--32">
      <h2 className="fs--24 fw--300 mb--32">Name</h2>
      <form onSubmit={handleEditUser}>
        <div className="display--flex flex--content-between gap--20 mt--16">
          <div>
            <label htmlFor="first_name" className="fs--12 f--uppercase">
              First Name*
            </label>
            <input
              id="first_name"
              name="firstName"
              className="br--8 border--none ph--16 pv--20 bg--dialog-input white w--100 fs--16 focus--outline-white"
              type="text"
              placeholder="First Name*"
              required
              autoFocus
              defaultValue={currentUser.firstName}
              onChange={handleInputChange}
              onFocus={handleFocus}
            />
          </div>
          <div>
            <label htmlFor="last_name" className="fs--12 f--uppercase">
              Last Name*
            </label>
            <input
              id="last_name"
              name="lastName"
              className="br--8 border--none ph--16 pv--20 bg--dialog-input white w--100 fs--16 focus--outline-white"
              type="text"
              placeholder="Last Name*"
              required
              defaultValue={currentUser.lastName}
              onChange={handleInputChange}
              onFocus={handleFocus}
            />
          </div>
        </div>
        {isNameEmpty && <div className="fs--14 red mt--8">Name can't be blank</div>}
        <div className="display--flex flex--content-end gap--16 mt--48">
          <Button utilityClasses="ph--12 br--pill lh--tight" type="submit" isDisabled={isSaveDisabled}>
            Save Changes
          </Button>
          <Button variant="secondary" utilityClasses="ph--12 br--pill lh--tight" onClick={handleCloseForm}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NameEditForm;
