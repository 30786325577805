import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

const Tooltip = ({ trigger, align = "center", side = "bottom", sideOffset = 8, children }) => {
  return (
    <div className="tooltip">
      <RadixTooltip.Provider delayDuration={200}>
        <RadixTooltip.Root>
          <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
          <RadixTooltip.Content sideOffset={sideOffset} side={side} align={align} className="tooltip__content">
            {children}
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      </RadixTooltip.Provider>
    </div>
  );
};

export default Tooltip;
