import React, { useContext } from "react";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";
import { deleteData } from "../../../utils/api";
import { UserContext } from "../../../UserContext";
import { useMembers, useMembersToast } from "../MembersContext";

const DeleteUserOption = ({ member }) => {
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();

  async function deleteMember() {
    try {
      const response = await deleteData(`/${currentUser.organizationId}/users/${member.id}`);
      dispatch({ type: "delete_member", payload: { member } });
      dispatchToast({ type: "success_toast", payload: response.notice || "User successfully deleted" });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  return (
    <Dialog
      trigger={
        <Button utilityClasses="pa--16 brbl--4 brbr--4" variant="tertiary" data-cy="popover__delete-active-user">
          Delete User
        </Button>
      }
      title="Delete User"
      description="Please confirm that you want to delete this user. This action cannot be undone. They will no longer have access to the MOSAIC suite of products."
      confirm={
        <Button
          onClick={deleteMember}
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
          data-cy="dialog__delete-active-user"
        >
          Yes, Delete User
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Cancel
        </Button>
      }
    >
      <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input">
        <Avatar initials={member.initials} size="lg" />
        <div>
          <h3 className="fs--18 fw--500">{member.name}</h3>
          <span className="fs--12">{member.email}</span>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteUserOption;
