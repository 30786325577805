import React from "react";

const batch = {
  "a-g": groupLetters(0, 7),
  "h-n": groupLetters(7, 14),
  "o-t": groupLetters(14, 20),
  "u-z": groupLetters(20, 26),
};

function groupLetters(start, end) {
  const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];
  return alphabet.slice(start, end);
}

const Avatar = ({ initials, color, size = "default" }) => {
  // use provided color if passed in
  const avatarColor = color ? color : getAvatarColor();

  function getAvatarColor() {
    if (isLetterInBatch(batch["a-g"])) return "blue";
    if (isLetterInBatch(batch["h-n"])) return "green";
    if (isLetterInBatch(batch["o-t"])) return "purple";
    if (isLetterInBatch(batch["u-z"])) return "orange";

    return "default";
  }

  function isLetterInBatch(batch) {
    const lastInitial = typeof initials === "string" && initials.split("").at(-1).toLowerCase();
    return batch.includes(lastInitial);
  }

  return (
    <div
      className={`avatar avatar--${size} avatar__color--${avatarColor}
        display--flex flex--center flex--item-shrink0 br--round f--uppercase fw--bold`}
    >
      {initials}
    </div>
  );
};

export default Avatar;
