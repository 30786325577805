import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";

const Slideout = ({ trigger, confirm, cancel, handleCancel, children }) => {
  return (
    <RadixDialog.Root>
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <RadixDialog.Portal className="slideout">
        <RadixDialog.Overlay className="slideout__overlay position--fix bg--dialog-overlay" />
        <RadixDialog.Content
          className="slideout__container position--fix bg--elevation-2 white pa--24"
          onEscapeKeyDown={handleCancel}
          onPointerDownOutside={handleCancel}
        >
          <div className="slideout__content display--flex flex--column gap--24">
            {children}
            <div className="display--flex gap--12 mt--auto">
              <RadixDialog.Close asChild>{confirm}</RadixDialog.Close>
              <RadixDialog.Close asChild onClick={handleCancel}>
                {cancel}
              </RadixDialog.Close>
            </div>
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export default Slideout;
