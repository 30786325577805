import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Billboard from "../Billboard";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const TwoFactor = (props) => {
  const [emailAuth, setEmailAuth] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const getActionLink = () => {
    if (emailAuth) {
      return `/organization/${props.org_id}/verifyemailtoken`;
    }

    if (props.otp_secret) {
      return `/organization/${props.org_id}/createtwofactor`;
    } else {
      return `/organization/${props.org_id}/verifytwofactor`;
    }
  };

  const toggleEmailAuth = () => {
    setEmailAuth(!emailAuth);

    if (!emailSent) {
      const csrfToken = document.querySelector("[name='csrf-token']").content;

      fetch("/sendemailtoken", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then((data) => {
          setEmailSent(true);
          console.log(data);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  const renderTitle = () => {
    if (emailAuth) {
      return (
        <React.Fragment>
          <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold", whiteSpace: "nowrap" }}>Enter Email Code</h1>
          <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
            Enter the code sent to your email.
          </p>
        </React.Fragment>
      );
    }

    if (props.otp_secret) {
      return (
        <React.Fragment>
          <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Set Up Authenticator</h1>
          <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
            Scan the QR code with your choice of authenticator.
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold", whiteSpace: "nowrap" }}>Enter Authenticator Code</h1>
          <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
            Enter the code from your authenticator.
          </p>
        </React.Fragment>
      );
    }
  };

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 150, marginBottom: 30 }} />

        {renderTitle()}
        <form method="POST" action={getActionLink()} className="authentication__form">
          <div className="">
            <img src={props.qr_code} style={{ textAlign: "center" }} />
            <input type="hidden" id="token" name="otp_secret" value={props.otp_secret} />
            <CustomTextField
              label="Authenticator Code"
              margin="normal"
              variant="outlined"
              id="code"
              name="otp_attempt"
              fullWidth
              required
              autoFocus
            />
          </div>
          <div className="auth__form__footer">
            <div className="auth__form_button_row" style={{ display: "flex", gap: "1em" }}>
              <a href="/logout">
                <button className="btn btn--light-big" style={{ flexGrow: 1, alignSelf: "inherit" }} type="button">
                  LOGOUT
                </button>
              </a>
              <button className="btn" style={{ flexGrow: 2, alignSelf: "inherit" }} type="submit">
                SUBMIT
              </button>
            </div>
            {/* <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>{emailAuth ? "Use your authenticator? " : "Can't use your authenticator right now? "}</span>
              <a className="authentication__link" onClick={toggleEmailAuth}>
                {emailAuth ? "Authenticate with authenticator" : "Authenticate with email"}
              </a>
            </div> */}
            {/* <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div> */}
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <Billboard />
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default TwoFactor;
