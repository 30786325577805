import React from "react";
import { MdCheck, MdClose } from "react-icons/md";

const Toggle = ({ label, utilityClasses, isOn, handleToggle, disabled = false, id }) => {
  function renderIcon() {
    if (!isOn) {
      return <MdClose color="#1c2437" size={12} />;
    } else if (isOn && disabled) {
      return <MdCheck color="#1c2437" size={12} />;
    } else {
      return <MdCheck color="#3f8ef2" size={12} />;
    }
  }

  return (
    <div className={`display--flex flex--content-between flex--items-center br--8 ${utilityClasses}`} id={id}>
      {label}
      <button
        className={`${isOn ? "toggle toggle--on" : "toggle"} position--relative bg--toggle br--pill`}
        onClick={handleToggle}
        disabled={disabled}
      >
        <span
          className={`
            ${isOn ? "toggle__thumb toggle__thumb--on" : "toggle__thumb"}
            ${isOn && !disabled ? "bg--white" : "bg--light-gray"}
            display--flex flex--center br--round
          `}
        >
          {renderIcon()}
        </span>
      </button>
    </div>
  );
};

export default Toggle;
