import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Billboard from "../Billboard";

import Dialog from "../elements/Dialog";
import Button from "../elements/Button";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const RequestPasswordReset = (props) => {
  // const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");

  const hasInvalidEmail = (email) => {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  // const submitForm = () => {
  //   document.querySelector("#password-reset-form").submit();
  // };

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 150, marginBottom: 30 }} />

        <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Forgot Password?</h1>
        <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
          Enter your email address to request a password reset.
        </p>

        <form id="password-reset-form" className="authentication__form" method="POST" action="/password_resets">
          <div className="">
            <CustomTextField
              label="email address"
              margin="normal"
              variant="outlined"
              id="email"
              fullWidth
              required
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
              autoFocus
            />
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
          </div>
          <div className="auth__form__footer">
            <button
              disabled={hasInvalidEmail(email)}
              className="btn"
              style={{ flexGrow: 1, alignSelf: "inherit" }}
              // onClick={() => {
              //   setShowModal(true);
              // }}
              type="submit"
            >
              Request Password Reset
            </button>
          </div>
        </form>
      </div>
    );
  };

  // const renderModal = () => {
  //   return (
  //     <Dialog
  //       open={showModal}
  //       setOpen={setShowModal}
  //       title="Password Reset"
  //       description="Are you sure you want to reset your password? We'll send the request to your team's admin. Your account will be disabled until your admin approves your password approval request."
  //       confirm={
  //         <Button onClick={() => submitForm()} utilityClasses="ph--8 br--pill lh--tight flex--item-grow1">
  //           Yes, Request Password Reset
  //         </Button>
  //       }
  //       cancel={
  //         <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
  //           Cancel
  //         </Button>
  //       }
  //     />
  //   );
  // };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        {/* {renderModal()} */}
        <Billboard />
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
