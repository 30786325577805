// TODO: Replace with Radix toast
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useMembersToast } from "./MembersContext";

export default function MemberToast() {
  const { toast, dispatchToast } = useMembersToast();

  const handleClose = () => {
    dispatchToast({ type: "close_toast" });
  };

  if (toast) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={toast.isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert elevation={5} variant="filled" onClose={handleClose} severity={toast.kind}>
          {toast.message}
        </Alert>
      </Snackbar>
    );
  }
}
