export const appCardData = [
  {
    id: "esp",
    name: "ESP",
    hasAccess: true,
    shortCopy:
      "ESP provides insight into compliance, submission quality, and compliance tracking as well as automated communication.",
    longCopy:
      "ESP provides information about Covered Entity compliance, Contract Pharmacy submission quality, automates communication, and tracks Covered Entity compliance over time.",
  },
  {
    id: "roundtable",
    name: "Roundtable",
    hasAccess: true,
    shortCopy:
      "Roundtable provides insight into operations and changes in the 340B Program, guiding its members in making more informed, efficient decisions.",
    longCopy:
      "Roundtable provides insight into operations and changes related to the federal drug pricing 340B Program to guide its members in making more efficient, competitive, and independent business decisions.",
  },
  {
    id: "ovi",
    name: "OVI",
    hasAccess: false,
    shortCopy:
      "Order Validation and Integrity details Covered Entity compliance by analyzing submission data and calculating purchasing power.",
    longCopy:
      "Order Validation and Integrity details Covered Entity compliance by analyzing submission data and its authenticity resulting in insight into available purchasing power.",
  },
  // {
  //   id: "mbs",
  //   name: "MBS MOSAIC",
  //   shortCopy:
  //     "ESP provides insight into compliance, submission quality, and compliance tracking as well as automated communication.",
  //   longCopy:
  //     "ESP provides information about Covered Entity compliance, Contract Pharmacy submission quality, automates communication, and tracks Covered Entity compliance over time.",
  // },
];
