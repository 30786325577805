import React, { useState } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const Dropdown = ({ options, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelection = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={isOpen ? "dropdown dropdown--open" : "dropdown"}>
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        className="display--flex flex--content-between flex--items-center gap--4 w--100 bg--elevation-3 br--8 ph--24 pv--16 fs--14"
      >
        <span className="display--flex flex--column gap--8">
          <span className="display--inline-flex flex--items-center gap--8">
            <span className="display--inline-flex fs--20">
              {selectedOption.icon}
            </span>
            <span className="fs--16 f--capitalize">{selectedOption.label}</span>
          </span>
          <span className="dropdown__wrap fs--10">{selectedOption.info}</span>
        </span>
        <span className="flex--item-shrink0 light-gray">
          {isOpen ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
        </span>
      </button>
      <div className="dropdown__options">
        <div className="dropdown__inner br--8">
          {options.map((option) => {
            return (
              <button
                key={option.value}
                onClick={() => handleSelection(option)}
                className="dropdown__option display--flex flex--content-between flex--items-center w--100 bg--elevation-3 ph--24 pv--16 fs--14"
              >
                <span className="display--flex flex--column gap--8">
                  <span className="display--inline-flex flex--items-center gap--8">
                    <span className="display--inline-flex fs--20">
                      {option.icon}
                    </span>
                    <span className="fs--16 f--capitalize">{option.label}</span>
                  </span>
                  <span className="fs--10">{option.info}</span>
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
