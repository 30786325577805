import React from "react";

const variantClasses = {
  primary: "pv--8 black bg--white fs--12 display--flex flex--center",
  secondary: "pv--8 white border fs--12 display--flex flex--center",
  tertiary: "white fs--10",
  link: "white fs--14 f--underline",
};

const Button = React.forwardRef((props, ref) => {
  const {
    variant = "primary",
    utilityClasses = "",
    type = "button",
    children,
    onClick,
    isDisabled = false,
    ...rest
  } = props;

  return (
    <button
      className={`button button--${variant} ${variantClasses[variant]} ${utilityClasses}`}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
      ref={ref}
      {...rest}
    >
      {children}
    </button>
  );
});

export default Button;
