import React, { useState, useContext } from "react";
import { MdOutlinePeopleAlt, MdOutlinePerson } from "react-icons/md";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";
import Toggle from "../../../elements/Toggle";
import Dropdown from "../../../elements/Dropdown";
import { Esp, Ovi, RoundTable } from "../../../Logos";
import { UserContext } from "../../../UserContext";
import { useMembers, useMembersToast } from "../MembersContext";
import { postData } from "../../../utils/api";

const options = [
  {
    value: "user",
    label: "Member",
    info: "The user has access to MOSAIC Hub and may have access to other applications.",
    icon: <MdOutlinePerson />,
  },
  {
    value: "admin",
    label: "Admin",
    info: "The user can manage team members’ product access, invite and confirm new users, and approve password reset requests.",
    icon: <MdOutlinePeopleAlt />,
  },
];

function Label({ logo, children }) {
  return (
    <span className="display--inline-flex flex--center gap--8">
      <span className="display--inline-flex bg--white br--4 pa--4">{logo}</span>
      <span>{children}</span>
    </span>
  );
}

const initialFormData = {
  email: "",
  firstName: "",
  lastName: "",
  role: "user",
  hasEspAccess: false,
  hasRtAccess: false,
  hasOviAccess: false,
};

const InviteNewUser = () => {
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();
  const [formData, setFormData] = useState(initialFormData);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const isValidEmail = formData.email && formData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const isSubmitDisabled = !isValidEmail || !formData.firstName || !formData.lastName;

  async function handleInviteMember() {
    try {
      const response = await postData(`/${currentUser.organizationId}/users`, formData);
      dispatch({ type: "invite", payload: { member: response.invite } });
      dispatchToast({ type: "success_toast", payload: response.notice || "Invite sent." });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  function handleDialogChange() {
    setFormData(initialFormData);
    setOpen((open) => !open);
  }

  function handleRoleSelection(option) {
    setSelectedOption(option);
    setFormData({ ...formData, role: option.value });
  }

  return (
    <Dialog
      open={open}
      setOpen={handleDialogChange}
      trigger={<Button utilityClasses="ph--12 br--pill lh--solid">Invite New User</Button>}
      title="Invite New User"
      description="Invite a new user to join your team on MOSAIC."
      confirm={
        <Button
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
          onClick={handleInviteMember}
          isDisabled={isSubmitDisabled}
          data-cy="send-invite-button"
        >
          Confirm
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight" data-cy="cancel-invite-button">
          Cancel
        </Button>
      }
    >
      <div className="display--flex flex--column gap--8 pb--32 border--b bc--white">
        <div>
          <label htmlFor="email" className="fs--12 f--uppercase">
            E-mail Address*
          </label>
          <input
            id="email"
            className="br--8 border--none pa--16 bg--dialog-input white focus--bc-white w--100 fs--16"
            type="email"
            placeholder="newuser@mosaic.com*"
            required
            defaultValue=""
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
        </div>
        <div className="display--flex flex--content-between gap--20 mt--16">
          <div>
            <label htmlFor="first_name" className="fs--12 f--uppercase">
              First Name*
            </label>
            <input
              id="first_name"
              className="br--8 border--none pa--16 bg--dialog-input white focus--bc-white w--100 fs--16"
              placeholder="First Name*"
              required
              defaultValue=""
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlFor="last_name" className="fs--12 f--uppercase">
              Last Name*
            </label>
            <input
              id="last_name"
              className="br--8 border--none pa--16 bg--dialog-input white focus--bc-white w--100 fs--16"
              placeholder="Last Name*"
              required
              defaultValue=""
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt--36">
        <div className="mb--36">
          <h3 className="mb--24 fs--18">Role</h3>
          <Dropdown selectedOption={selectedOption} options={options} onSelect={handleRoleSelection} />
        </div>
        <h3 className="fs--18 mb--24">Product Access</h3>
        <div className="display--flex flex--column gap--16">
          <Toggle
            id="esp-toggle"
            label={<Label logo={<Esp width={16} height={16} fill="blue" />}>ESP</Label>}
            utilityClasses="ph--12"
            isOn={formData.hasEspAccess}
            handleToggle={() => {
              setFormData({ ...formData, hasEspAccess: !formData.hasEspAccess });
            }}
            disabled={!currentUser.mfrPermissions.esp}
          />
          <Toggle
            id="rt-toggle"
            label={<Label logo={<RoundTable width={16} height={16} fill="blue" />}>Roundtable</Label>}
            utilityClasses="ph--12"
            isOn={formData.hasRtAccess}
            handleToggle={() => {
              setFormData({ ...formData, hasRtAccess: !formData.hasRtAccess });
            }}
            disabled={!currentUser.mfrPermissions.roundtable}
          />
          <Toggle
            id="ovi-toggle"
            label={<Label logo={<Ovi width={16} height={16} fill="blue" />}>OVI</Label>}
            utilityClasses="ph--12"
            isOn={formData.hasOviAccess}
            handleToggle={() => {
              setFormData({ ...formData, hasOviAccess: !formData.hasOviAccess });
            }}
            disabled={!currentUser.mfrPermissions.ovi}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default InviteNewUser;
