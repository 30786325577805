import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  root: {
    maxWidth: "50%",
  },
});

export default function FlashMessage(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
  });

  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        setState({ ...state, open: true });
      }, 1500);
    } else {
      setState({ ...state, open: true });
    }
  }, []);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={state.open}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={props.kind}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
