import React from "react";
import { MdOutlinePeopleAlt, MdVerifiedUser, MdKey } from "react-icons/md";
import { useMembers } from "./MembersContext";
import Slideout from "../../elements/Slideout";
import Button from "../../elements/Button";
import Avatar from "../../elements/Avatar";
import Tag from "../../elements/Tag";
import Toggle from "../../elements/Toggle";
import Tooltip from "../../elements/Tooltip";
import Member from "../../elements/Member";
import MemberHeading from "../../elements/MemberHeading";
import { Esp, Ovi, RoundTable } from "../../Logos";
import { getMonthYear } from "../../utils/formatDate";

export default function MemberView() {
  const {
    byStatus: { active },
  } = useMembers();

  return (
    <div className="display--flex flex--column gap--16 border--t bc--gray">
      <h2 className="fs--24 fw--300 lh--tight mb--32 mt--40">Active Members</h2>
      <MemberHeading headings={["Name", "Product Access", "Last Active"]} />
      {active.map((member) => (
        <Member key={member.id} member={member}>
          <Slideout
            trigger={
              <Button variant="secondary" utilityClasses="br--pill ph--12">
                View Details
              </Button>
            }
          >
            <Header {...member} />
            <Role role={member.role} />
            <ProductAccess products={member.products} />
          </Slideout>
        </Member>
      ))}
    </div>
  );
}

function Header({ initials, name, created_at }) {
  return (
    <header className="pt--32 pb--24 ph--24 br--12 bg--elevation-3">
      <section className="display--flex flex--column flex--items-center gap--16">
        <Avatar initials={initials} size="lg" />
        <h2 className="fs--24 fw--600 lh--tight text--c">{name}</h2>
        <div className="display--flex gap--8">
          <Tag utilityClasses="bg--none border bc--badge">
            <MdOutlinePeopleAlt size={14} />
            <span>Joined {getMonthYear(created_at)}</span>
          </Tag>
          <Tag utilityClasses="bg--none border bc--badge">
            <MdVerifiedUser color="#24b668" size={14} />{" "}
            <span>
              <strong>2fa:</strong> On
            </span>
          </Tag>
        </div>
      </section>
    </header>
  );
}

function Role({ role }) {
  return (
    <section className="bg--elevation-3 br--8 ph--24 pv--16 mt--24 mb--24 fs--14">
      <p className="display--inline-flex flex--items-center gap--8 mb--16">
        <MdOutlinePeopleAlt />
        <span className="fs--16 f--capitalize">{role === "user" ? "Member" : "Admin"}</span>
      </p>
      <p className="fs--10">
        {role === "user"
          ? "The user has access to this application"
          : "The user can manage team members for this application"}
      </p>
    </section>
  );
}

function ProductAccess({ products }) {
  function hasAccessTo(productName) {
    return products.findIndex((product) => product.name === productName) !== -1;
  }
  return (
    <section className="bg--elevation-3 pv--16 ph--24 br--12">
      <h4 className="display--flex flex--items-center gap--8 mb--24 fs--16">
        <MdKey /> Product Access
      </h4>
      <div className="display--flex flex--column gap--24">
        <ToggleTooltip
          trigger={
            <Toggle
              label={<ToggleLabel logo={<Esp width={16} height={16} />} label="ESP" />}
              isOn={hasAccessTo("esp")}
              handleToggle={null}
              disabled
            />
          }
        />
        <ToggleTooltip
          trigger={
            <Toggle
              label={<ToggleLabel logo={<RoundTable width={16} height={16} />} label="Roundtable" />}
              isOn={hasAccessTo("roundtable")}
              handleToggle={null}
              disabled
            />
          }
        />
        <ToggleTooltip
          trigger={
            <Toggle
              label={<ToggleLabel logo={<Ovi width={16} height={16} />} label="OVI" />}
              isOn={hasAccessTo("ovi")}
              handleToggle={null}
              disabled
            />
          }
        />
      </div>
    </section>
  );
}

function ToggleLabel({ logo, label }) {
  return (
    <div className="display--flex flex--center gap--12">
      <span className="display--inline-flex bg--black-v3 br--6 pa--8">{logo}</span>
      <span className="fw--300">{label}</span>
    </div>
  );
}

function ToggleTooltip({ trigger }) {
  return (
    <Tooltip align="end" trigger={<div>{trigger}</div>}>
      <div className="display--flex flex--column gap--4 pa--12 bg--white black br--4 fs--12 w--max-200px">
        <span className="fw--bold">Product Access</span>
        <span>Only administrators of MOSAIC can change another user’s access.</span>
      </div>
    </Tooltip>
  );
}
