import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Billboard from "../Billboard";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const Blocked = (props) => {
  const renderForm = () => {
    return (
      <div className="auth__form">
        <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>{props.title}</h1>
        <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
          {props.message}
        </p>
        <div className="authentication__form">
          <a href="/logout">
            <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }}>
              Logout
            </button>
          </a>
          {/* <div className="authentication__info" style={{ marginTop: 20 }}>
            <span>Need help? </span>
            <a className="authentication__link" href="/">
              Contact Us
            </a>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <Billboard />
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default Blocked;
