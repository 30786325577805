import { useContext, useState } from "react";
import wretch from "wretch";
import { UserContext } from "../UserContext";

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

export default function useDownloadReport() {
  const { currentUser } = useContext(UserContext);
  const [downloadState, setDownloadState] = useState({});

  const downloadFile = async (endpoint = "eoy-report", key = "eoy-report") => {
    const currentDownloadState = { error: null };
    setDownloadState((prev) => ({ ...prev, [key]: currentDownloadState }));

    try {
      const response = wretch(`/organization/${currentUser.organizationId}/${endpoint}`)
        .content("application/pdf")
        .auth(csrfToken)
        .get();
      const res = await response.res();
      const blob = await response.blob();
      const contentHeader = res.headers?.get("Content-Disposition");
      const file = window.URL.createObjectURL(blob);
      const fileName = contentHeader?.split("filename*=UTF-8''").at(-1) || "report.pdf";
      const downloadLink = document.createElement("a");

      downloadLink.href = file;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      currentDownloadState.error = error.message;
      setDownloadState((prev) => ({ ...prev, [key]: currentDownloadState }));
    }
  };

  return { downloadState, downloadFile };
}
