import React, { useContext } from "react";
import Avatar from "../../../elements/Avatar";
import Button from "../../../elements/Button";
import Dialog from "../../../elements/Dialog";
import { UserContext } from "../../../UserContext";
import { postData } from "../../../utils/api";
import { useMembers, useMembersToast } from "../MembersContext";

const ResendResetPassword = ({ member }) => {
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useMembers();
  const { dispatchToast } = useMembersToast();

  async function resendResetPassword() {
    try {
      const response = await postData(`/${currentUser.organizationId}/resend_reset_password_email`, { id: member.id });
      dispatch({ type: "resend_reset_password", payload: { member: response.user } });
      dispatchToast({ type: "success_toast", payload: response.notice || "Password reset email successfully resent." });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  return (
    <Dialog
      trigger={
        <Button variant="secondary" utilityClasses="br--pill ph--12">
          Resend Reset Password
        </Button>
      }
      title="Resend Invitation"
      description="Resend your invitation to join your team on MOSAIC."
      confirm={
        <Button
          onClick={resendResetPassword}
          utilityClasses="ph--8 br--pill lh--tight flex--item-grow1"
          data-cy="resend-reset-password-confirmation-button"
        >
          Confirm
        </Button>
      }
      cancel={
        <Button variant="secondary" utilityClasses="ph--24 br--pill lh--tight">
          Cancel
        </Button>
      }
    >
      <div className="display--flex flex--items-center gap--16 pa--24 br--8 bg--dialog-input">
        <Avatar initials={member.initials} size="lg" />
        <div>
          <h3 className="fs--18 fw--500">{member.name}</h3>
          <span className="fs--12">{member.email}</span>
        </div>
      </div>
    </Dialog>
  );
};

export default ResendResetPassword;
