import React from "react";
import { MdArrowRightAlt, MdHelpOutline } from "react-icons/md";
import { Mosaic } from "../../Logos";
import Popover from "../../elements/Popover";

export default function MbsCard(props) {
  const { error, total } = props;

  return (
    <div className={`bg--mid-gray-v2 br--4 white tile position--rel ${error ? "error" : "mbs"}`}>
      <div className="display--flex ml--24 mt--24">
        <div className="display--flex flex--items-center gap--8 pv--4 ph--8 br--4 fs--12 bg--badge">
          <Mosaic width={14} height={14} />
          <span>MBS</span>
        </div>
      </div>
      <div className="position--abs top--48 right--0">
        <SvgLargeCircle className="mbs mbs__svg--lg-circle position--abs top--16 right--0" />
        <SvgSmallCircle className="mbs mbs__svg--sm-circle position--abs right--0" />
        <SvgTriangle className="mbs mbs__svg--triangle position--abs right--32" />
      </div>
      <CardContent hasError={!!error} total={total} />
      <div className="mbs__footer">
        <SvgShapesCollection className="mbs__svg--collection position--abs left--0 bg--dark-gray" hasError={!!error} />
        <div className="display--flex flex--items-center flex--content-end gap--4 pa--16 position--rel pt--48">
          <CardCta hasError={!!error} />
        </div>
      </div>
    </div>
  );
}

function CardContent({ hasError, total }) {
  if (hasError) {
    return <p className="pa--24">This account is not currently eligible for an MBS report.</p>;
  }
  return (
    <div className="pa--24">
      <div className="display--flex lh--solid mb--12">
        <strong className="display--flex">
          <span className="fw--200 fs--32">$</span>
          <span className="fw--500 fs--40">{total?.amount}</span>
          <span className="fw--500 fs--32 flex--self-end">{total?.letter}</span>
        </strong>
      </div>
      <p>in duplicate discounts on patients since 2018.</p>
    </div>
  );
}

function CardCta({ hasError }) {
  if (hasError) {
    return (
      <Popover
        trigger={
          <button className="display--flex flex--items-center gap--4">
            <span className="fs--12">Learn Why</span> <MdHelpOutline size={12} />
          </button>
        }
        align="end"
      >
        <div className="w--max-255px bg--elevation-1 pa--16 fs--10 gray-30 br--8 border bc--badge">
          <p className="mb--8">This account does not have a Dual Eligible Duplicate Discount report.</p>
          <p>
            Contact{" "}
            <a href="mailto:kerb@thinkbrg.com" className="f--underline">
              kerb@thinkbrg.com
            </a>{" "}
            with questions.
          </p>
        </div>
      </Popover>
    );
  }
  return (
    <>
      <span className="fs--12">Learn More</span> <MdArrowRightAlt className="mbs mbs__svg--arrow fs--20" />
    </>
  );
}

function SvgLargeCircle({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="27"
      viewBox="0 0 21 27"
      fill="none"
    >
      <rect
        x="18.9121"
        y="31.1826"
        width="25.9906"
        height="25.9906"
        rx="12.9953"
        transform="rotate(-151.829 18.9121 31.1826)"
        fill="#287dfc"
      />
    </svg>
  );
}

function SvgSmallCircle({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 7 11" fill="none">
      <rect
        width="9.86886"
        height="9.86887"
        rx="4.93443"
        transform="matrix(0.881539 0.472111 0.472111 -0.881539 -0.929688 7.77002)"
        fill="#3246D3"
      />
    </svg>
  );
}

function SvgTriangle({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        opacity="0.6"
        d="M0.641432 1.75716C8.65001 -0.808018 17.2965 3.37513 20.2369 11.2865L5.83828 16.3975L0.641432 1.75716Z"
        stroke="#28D0FC"
      />
    </svg>
  );
}
function SvgShapesCollection({ hasError, className }) {
  if (hasError) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="94"
        viewBox="0 0 266 94"
        fill="none"
        className={className}
      >
        <rect x="-60" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".05" />
        <rect x="-30" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".1" />
        <rect x="-1" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".05" />
        <path fill="#fff" fillOpacity=".1" d="M59.035 30.737H29.518V.969h29.517z" />
        <path d="M88.802.969v29.517H59.035C59.035 14.184 72.5.97 88.802.97z" fill="#fff" fillOpacity=".05" />
        <path d="M88.802.969h29.518v29.767c-16.302 0-29.518-13.465-29.518-29.767z" fill="#fff" fillOpacity=".1" />
        <rect x="118.32" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".05" />
        <path fill="#fff" fillOpacity=".05" d="M177.355 30.737h-29.517V.969h29.517z" />
        <rect
          x="206.499"
          y="30.363"
          width="28.77"
          height="29.02"
          rx="14.385"
          transform="rotate(180 206.499 30.363)"
          stroke="#28D0FC"
          strokeWidth=".747"
        />
        <path d="M206.873 30.486V.97h29.767c0 16.302-13.465 29.517-29.767 29.517z" fill="#fff" fillOpacity=".05" />
        <rect x="236.64" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".1" />
        <rect x="266.64" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".05" />
        <rect x="295.64" y=".969" width="29.517" height="29.768" rx="14.759" fill="#fff" fillOpacity=".1" />
      </svg>
    );
  } else {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="621"
        height="94"
        viewBox="0 0 621 94"
        fill="none"
      >
        <rect width="620.873" height="93.304" fill="#242D6B" />
        <rect
          x="29.5176"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          rx="14.7587"
          transform="rotate(180 29.5176 29.7676)"
          fill="#3246D3"
        />
        <rect
          x="59.0352"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          transform="rotate(180 59.0352 29.7676)"
          fill="#287DFC"
        />
        <path
          d="M88.8027 0L88.8027 29.5175L59.0351 29.5175V29.5175C59.0351 13.2154 72.5007 1.46302e-07 88.8027 0V0Z"
          fill="#3246D3"
        />
        <path
          d="M88.8027 0L118.32 -5.20473e-06L118.32 29.7676V29.7676C102.018 29.7676 88.8027 16.3021 88.8027 0V0Z"
          fill="#287DFC"
        />
        <rect x="118.32" width="29.5175" height="29.7676" rx="14.7587" fill="#3246D3" />
        <rect
          x="177.355"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          transform="rotate(180 177.355 29.7676)"
          fill="#3246D3"
        />
        <rect
          x="206.499"
          y="29.3939"
          width="28.7701"
          height="29.0202"
          rx="14.3851"
          transform="rotate(180 206.499 29.3939)"
          stroke="#28D0FC"
          strokeWidth="0.747343"
        />
        <path
          d="M206.873 29.5176L206.873 9.91821e-05L236.641 9.96249e-05V9.96249e-05C236.641 16.3021 223.175 29.5176 206.873 29.5176V29.5176Z"
          fill="#3246D3"
        />
        <rect x="236.641" width="29.5175" height="29.7676" rx="14.7587" fill="#287DFC" />
        <rect x="266.158" width="29.5175" height="29.7676" rx="14.7587" fill="#3246D3" />
        <rect
          x="324.816"
          y="29.186"
          width="25.5066"
          height="26.5023"
          transform="rotate(180 324.816 29.186)"
          fill="#287DFC"
          fillOpacity="0.5"
        />
        <rect
          x="321.446"
          y="26.0618"
          width="24.6252"
          height="25.6209"
          transform="rotate(180 321.446 26.0618)"
          stroke="#28D0FC"
          strokeWidth="0.881362"
        />
        <rect
          x="354.715"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          rx="14.7587"
          transform="rotate(180 354.715 29.7676)"
          fill="#3246D3"
        />
        <path
          d="M354.715 29.5176L354.715 9.91821e-05L384.482 9.96249e-05V9.96249e-05C384.482 16.3021 371.017 29.5176 354.715 29.5176V29.5176Z"
          fill="#3246D3"
        />
        <rect x="384.482" width="29.5175" height="29.7676" rx="14.7587" fill="#287DFC" />
        <rect
          x="443.518"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          rx="14.7587"
          transform="rotate(180 443.518 29.7676)"
          fill="#3246D3"
        />
        <rect
          x="473.035"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          transform="rotate(180 473.035 29.7676)"
          fill="#287DFC"
        />
        <path
          d="M502.803 0L502.803 29.5175L473.035 29.5175V29.5175C473.035 13.2154 486.501 1.46302e-07 502.803 0V0Z"
          fill="#3246D3"
        />
        <path
          d="M502.803 0L532.32 -5.20473e-06L532.32 29.7676V29.7676C516.018 29.7676 502.803 16.3021 502.803 0V0Z"
          fill="#287DFC"
        />
        <rect x="532.32" width="29.5175" height="29.7676" rx="14.7587" fill="#3246D3" />
        <rect
          x="591.355"
          y="29.7676"
          width="29.5175"
          height="29.7676"
          transform="rotate(180 591.355 29.7676)"
          fill="#3246D3"
        />
        <rect
          x="620.499"
          y="29.3939"
          width="28.7701"
          height="29.0202"
          rx="14.3851"
          transform="rotate(180 620.499 29.3939)"
          stroke="#28D0FC"
          strokeWidth="0.747343"
        />
      </svg>
    );
  }
}
