import React from "react";
import { MdInsertLink, MdOutlineEvent, MdAccessTime } from "react-icons/md";
import BaseCard from "./BaseCard";
import ErrorCard from "../../elements/ErrorCard";
import { RoundTable } from "../../Logos";

export default function RoundtableEventsCard({ events }) {
  const hasEvents = !events.error && Object.keys(events).length > 0;
  const hasUpcomingEvent = events.upcoming_event && Object.keys(events.upcoming_event).length;

  if (!hasEvents) {
    return <ErrorCard app="roundtable">We cannot retrieve Roundtable event information at this time.</ErrorCard>;
  }

  if (!hasUpcomingEvent) {
    const { image, link, title, link_password: pass } = events.latest_event;
    return (
      <a href={link} target="_blank">
        <BaseCard>
          <BaseCard.FullSection>
            <Image src={image} />
          </BaseCard.FullSection>
          <BaseCard.BadgeContainer className="bg--badge-transparent position--abs top--24">
            <RoundTable width={14} height={14} />
            <span>Roundtable</span>
          </BaseCard.BadgeContainer>
          <BaseCard.Section>
            <BaseCard.SecondaryText>Events</BaseCard.SecondaryText>
            <BaseCard.CopyContainer>{title}</BaseCard.CopyContainer>
            {pass && (
              <div className="display--flex gap--16">
                <BaseCard.TagContainer className="border bc--badge br--6 mt--8">
                  <MdInsertLink /> <span>Password: {pass}</span>
                </BaseCard.TagContainer>
              </div>
            )}
          </BaseCard.Section>
        </BaseCard>
      </a>
    );
  } else {
    const { date, image, location, title } = events.upcoming_event;
    return (
      <BaseCard>
        <BaseCard.FullSection>
          <Image src={image} />
        </BaseCard.FullSection>
        <BaseCard.BadgeContainer className="bg--badge-transparent position--abs top--24">
          <RoundTable width={14} height={14} />
          <span>Roundtable</span>
        </BaseCard.BadgeContainer>
        <BaseCard.Section>
          <BaseCard.SecondaryText>Events</BaseCard.SecondaryText>
          <BaseCard.CopyContainer>{title}</BaseCard.CopyContainer>
          <div className="display--flex gap--16">
            <BaseCard.TagContainer className="border bc--badge br--6 mt--8">
              <MdInsertLink /> <span>{location}</span>
            </BaseCard.TagContainer>
            <BaseCard.TagContainer className="border bc--badge br--6 mt--8">
              <MdOutlineEvent /> <span>{formatDate(date)}</span>
            </BaseCard.TagContainer>
            <BaseCard.TagContainer className="border bc--badge br--6 mt--8">
              <MdAccessTime /> <span>{formatTime(date)}</span>
            </BaseCard.TagContainer>
          </div>
        </BaseCard.Section>
      </BaseCard>
    );
  }
}

function formatDate(date) {
  return new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "numeric" }).format(new Date(date));
}

function formatTime(date) {
  return new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" }).format(new Date(date));
}

function Image({ src = "/assets/roundtable-events.jpg" }) {
  return (
    <img
      src={src}
      alt="roundtable upcoming event image"
      className="brtl--12 brtr--12"
      style={{ objectFit: "cover", width: "100%", height: 216 }}
    />
  );
}
