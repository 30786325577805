import React from "react";
import { MdCircle, MdLockOutline } from "react-icons/md";
import { Esp, Mosaic, Ovi, RoundTable } from "../Logos";
import Avatar from "./Avatar";
import Tag from "./Tag";
import Tooltip from "./Tooltip";
import _ from "lodash";

const apps = {
  esp: <Esp width={20} height={20} />,
  roundtable: <RoundTable width={20} height={20} />,
  ovi: <Ovi width={20} height={20} />,
  mbs: <Mosaic width={20} height={20} />,
};

const appNames = {
  esp: "ESP",
  roundtable: "RoundTable",
  ovi: "OVI",
  mbs: "MBS",
};

const PENDING_STATES = ["pending", "registered"];

function LockedMemberAvatar() {
  return (
    <Tooltip
      align="start"
      trigger={
        <div>
          <Avatar initials={<MdLockOutline size="50%" color="#fff" />} />
        </div>
      }
    >
      <div className="display--flex flex--column gap--4 pa--12 bg--white black br--4 fs--12 w--max-200px">
        <span className="fw--bold">Locked Account</span>
        <span>This member has lost access to MOSAIC until they reset their password</span>
      </div>
    </Tooltip>
  );
}

function ProductTooltipContent({ productName, isPending }) {
  return (
    <div className="display--flex flex--column gap--4 pa--12 bg--white black br--4 fs--12">
      <span className="fw--bold">{isPending && "Pending"} Member</span>
      <span>
        This member {isPending ? "will have" : "has"} access to {appNames[productName]}
      </span>
    </div>
  );
}

const Member = ({ member, children, showExpiration, locked, actionRequired }) => {
  // TODO: refactor
  function renderProducts() {
    if (!member?.products.length) return;

    function sortProducts() {
      const productOrder = ["esp", "roundtable", "ovi"];

      return _.sortBy(member.products, (product) => {
        return _.indexOf(productOrder, product.name);
      });
    }

    return sortProducts().map((product) => {
      return (
        <div
          className="display--flex flex--column flex--items-center"
          key={product.name}
          data-cy={`product-logo-${product.name}`}
        >
          <Tooltip
            align="start"
            trigger={<div className="display--flex bg--black-v3 hover--product br--6 pa--8">{apps[product.name]}</div>}
          >
            <ProductTooltipContent
              productName={product.name}
              isPending={PENDING_STATES.includes(member.activation_state)}
            />
          </Tooltip>
        </div>
      );
    });
  }

  const formatDate = (date, showTime = false) => {
    const newDate = new Date(date);
    if (showTime) {
      const formattedDate = newDate.toLocaleString("en-US", { dateStyle: "medium" });
      const formattedTime = newDate.toLocaleString("en-US", { timeStyle: "short" });
      return formattedDate + " " + formattedTime;
    }
    if (newDate < new Date()) {
      return (
        <div>
          {newDate.toLocaleString("en-US", { dateStyle: "medium" })} <span className="t--disabled">(Expired)</span>
        </div>
      );
    }
    return newDate.toLocaleString("en-US", { dateStyle: "medium" });
  };

  // TODO: refactor
  const renderTimeStamp = () => {
    if (showExpiration && member.invite_expires_at) {
      return formatDate(member.invite_expires_at);
    }
    if (showExpiration && !member.invite_expires_at) {
      return "--";
    }
    if (member.last_active !== null) {
      return formatDate(member.last_active, true);
    }
    return "--";
  };

  return (
    <div className="member display--grid bg--dark-gray ph--24 br--8 gap--32 position--rel" data-cy="user-component">
      {actionRequired && (
        <MdCircle size={8} color="#e91e63" className="position--abs left--8" data-cy="action-required-member-tag" />
      )}
      <div className="display--flex flex--items-center gap--16">
        {locked ? <LockedMemberAvatar /> : <Avatar initials={member.initials} />}
        <div>
          <h3 className="member__name-container fs--18 fw--500 lh--tight display--flex flex--items-center gap--8 position--rel">
            <span className={`member__name t--nowrap bg--elevation-1 ${member.name.length > 18 ? "t--truncate" : ""}`}>
              {member.name}
            </span>
            {member.role === "admin" && <Tag utilityClasses="member__tag bg--blue-v4 white fw--lite pv--4">Admin</Tag>}
          </h3>
          <span className="fs--12 display--inline-block w--max-250px t--truncate">{member.email}</span>
        </div>
      </div>
      <div className="display--flex flex--items-end gap--8">{renderProducts()}</div>
      <div className="fs--14" data-cy="user-timestamp">
        {renderTimeStamp()}
      </div>
      <div className="display--flex flex--items-center gap--8 ml--auto">{children}</div>
    </div>
  );
};

export default Member;
