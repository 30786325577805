import React from "react";
import { appCardData } from "../../utils/appCardData";
import Layout from "../../Layout";
import AppCard from "../../elements/AppCard";

const AppsIndex = (props) => {
  return (
    <Layout user={{ ...props.user, hasPendingActions: props.hasPendingActions }}>
      <div className="apps">
        <h2 className="bg--mid-gray-v2 pl--32 pv--20 mb--44 br--12 fs--24 lh--tight fw--300">
          Explore the MOSAIC suite of apps.
        </h2>
        <div className="display--grid grid--apps gap--28">
          {appCardData.map((app) => (
            <AppCard
              key={app.id}
              appId={app.id}
              appName={app.name}
              appUrl={`https://${app.id}.${props.mfrShortName}.thinkmosaic.com`}
              hasAccess={props.user.permissions[app.id]}
              data-cy={`apps__${app.id}-card`}
            >
              {app.longCopy}
            </AppCard>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default AppsIndex;
