import React from "react";
import { Esp, Mosaic, Ovi, RoundTable } from "../Logos";

const apps = {
  esp: {
    name: "ESP",
    logo: (size, fill) => <Esp width={size || 12} height={size || 12} fill={fill || "dark"} />,
  },
  roundtable: {
    name: "Roundtable",
    logo: (size, fill) => <RoundTable width={size || 12} height={size || 12} fill={fill || "dark"} />,
  },
  ovi: {
    name: "OVI",
    logo: (size, fill) => <Ovi width={size || 12} height={size || 12} fill={fill || "dark"} />,
  },
  mbs: {
    name: "MBS",
    logo: (size, fill) => <Mosaic width={size || 12} height={size || 12} fill={fill || "dark"} />,
  },
};

const AppBadge = ({ app, size, fill }) => {
  if (!app) return;
  return (
    <span className={`display--inline-flex flex--items-center ${size > 24 ? "gap--12" : "gap--8"}`}>
      <span className={`display--inline-flex bg--white ${size > 24 ? "pa--12 br--8" : "pa--4 br--4"}`}>
        {apps[app].logo(size, fill)}
      </span>
      <span className="fs--12">{apps[app].name}</span>
    </span>
  );
};

export default AppBadge;
