export const chartColors = [
  "#287DFC",
  "#C382F2",
  "#FEC767",
  "#2BD67B",
  "#28D0FC",
  "#FFA877",
  "#5E71F5",
  "#AA25AA",
  "#3246D3",
  "#FF681F",
  "#242D6B",
  "#008080",
  "#8957EE",
  "#24B668",
  "#C9D8FF",
  "#92D460",
  "#718291",
  "#E91E63",
];
