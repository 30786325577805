import wretch from "wretch";

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

// Initialize wretch
const api = wretch("/organization")
  .headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRF-Token": csrfToken,
  })
  .resolve((r) => r.json());

export function getData(endpoint) {
  return api.get(endpoint);
}

export function postData(endpoint, payload) {
  return api.url(endpoint).post(payload);
}

export function patchData(endpoint, payload) {
  return api.url(endpoint).patch(payload);
}

export function deleteData(endpoint) {
  return api.url(endpoint).delete();
}
