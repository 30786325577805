import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Billboard from "../Billboard";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const SignIn = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 150, marginBottom: 30 }} />

        <h1 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>Welcome Back</h1>
        <p className="t--subtitle" style={{ marginBottom: "1rem" }}>
          Log into your account
        </p>

        <form method="POST" action="/login" className="authentication__form">
          <div className="">
            <CustomTextField
              label="Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="session[email]"
              fullWidth
              required
              autoFocus
            />
            <CustomTextField
              label="Password"
              margin="normal"
              variant="outlined"
              id="password"
              fullWidth
              required
              name="session[password]"
              type="password"
            />
          </div>
          <div className="auth__form__footer">
            <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }} type="submit">
              LOGIN
            </button>

            <a className="authentication__link" href="/password_resets/new" style={{ marginTop: 20 }}>
              Forgot Password?
            </a>
            {/* <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div> */}
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <Billboard />
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default SignIn;
