import React, { useState } from "react";
import {
  MdPersonOutline,
  MdOutlineUpdate,
  MdOutlinePeopleAlt,
  MdRemoveRedEye,
  MdExpandMore,
  MdExpandLess,
  MdOutlineMailOutline,
  MdOutlineFileDownload,
} from "react-icons/md";
import Popover from "../../elements/Popover";
import Avatar from "../../elements/Avatar";
import Button from "../../elements/Button";
import Tag from "../../elements/Tag";

export default function Aside(props) {
  return (
    <div className="display--flex flex--column gap--20">
      <AsideSection>
        <AsideHeader title={props.mfr.name} icon={<MdPersonOutline color="#0059F5" />} />
        <div className="mb--12 fs--12">
          <div>Policy announcement - {props.mfr.policy_announcement}</div>
          <div>Policy enforcement - {props.mfr.policy_enforcement}</div>
        </div>
        <a className="display--inline-flex flex--items-center gap--4 fs--12" href={props.mfr.letter} target="_blank">
          <MdOutlineFileDownload /> <span className="f--underline white lh--tight">Download Manufacturer Letter</span>
        </a>
      </AsideSection>
      <AsideSection>
        <AsideHeader title="Updates" icon={<MdOutlineUpdate color="#0059F5" />} />
        <div className="home__updates">
          <Updates {...props.updates} isAdmin={props.user.isAdmin} />
        </div>
      </AsideSection>
      <TeamMembers members={props.members} />
      <AsideSection isLastChild>
        <AsideHeader title="Activity" icon={<MdRemoveRedEye color="#0059F5" />} />
        <ActivityFeed activities={props.activities} />
      </AsideSection>
    </div>
  );
}

function AsideSection({ isLastChild, children }) {
  if (isLastChild) return <article>{children}</article>;
  return <article className="border--b bc--badge pb--20">{children}</article>;
}

function AsideHeader({ title, icon, children }) {
  return (
    <h2 className="fs--14 mb--16 display--flex flex--content-between">
      <span className="display--inline-flex flex--items-center gap--4">
        {icon} <span>{title}</span>
      </span>
      {children}
    </h2>
  );
}

function Updates({ pending, locked, isAdmin }) {
  if (!isAdmin || (!pending && !locked)) {
    return <p className="mid-gray fs--12">There are no new updates right now.</p>;
  }

  return (
    <div className="display--flex flex--column gap--8">
      {!!pending && (
        <a href="/organization/4393ae70-6d3b-43bb-9d48-253bed6c5881/users#pending">
          <div className="pv--4 br--6 hover hover--update card__update display--flex gap--8">
            <p className="fs--12 lh--tight display--flex flex--column">
              <span className="white">
                {pending} New User{pending > 1 && "s"} awaiting your confirmation
              </span>
              <span className="mid-gray">in User Management</span>
            </p>
          </div>
        </a>
      )}
      {!!locked && (
        <a href="/organization/4393ae70-6d3b-43bb-9d48-253bed6c5881/users#locked">
          <div className="pv--4 br--6 hover hover--update card__update display--flex gap--8">
            <p className="fs--12 lh--tight display--flex flex--column">
              <span className="white">
                {locked} User{locked > 1 && "s"} requested a password reset
              </span>
              <span className="mid-gray">in User Management</span>
            </p>
          </div>
        </a>
      )}
    </div>
  );
}

function TeamMembers({ members }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const FIRST_ROW = 11;
  const hasMoreThanOneRow = members.length > FIRST_ROW;
  const firstRowMembers = members.slice(0, FIRST_ROW - 1);
  const remainingMembersCount = members.slice(FIRST_ROW - 1).length;

  return (
    <AsideSection>
      <AsideHeader title="Team Members" icon={<MdOutlinePeopleAlt color="#0059F5" />}>
        {/* Not using the Button componenet as this is different than everything else */}
        {hasMoreThanOneRow && (
          <button
            className="display--flex flex--items-center gap--4 fs--12 light-gray hover--update pa--4 br--4"
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
          >
            {isExpanded ? (
              <>
                See Less <MdExpandLess />
              </>
            ) : (
              <>
                See More <MdExpandMore />
              </>
            )}
          </button>
        )}
      </AsideHeader>
      <div className="display--flex flex--wrap flex--items-center gap--4">
        {hasMoreThanOneRow && !isExpanded ? (
          <>
            {firstRowMembers.map((member) => (
              <MemberPopover {...member} key={member.id} />
            ))}
            <span className="fs--12">+{remainingMembersCount}</span>
          </>
        ) : (
          <>
            {members.map((member) => (
              <MemberPopover {...member} key={member.id} />
            ))}
          </>
        )}
      </div>
    </AsideSection>
  );
}

function MemberPopover({ initials, name, role, email, last_active }) {
  return (
    <Popover
      side="bottom"
      trigger={
        <Button variant="tertiary">
          <Avatar size="sm" initials={initials} />
        </Button>
      }
    >
      <article className="bg--elevation-1 pt--24 pb--8 ph--8 br--8 border bc--badge w--266px">
        <header className="display--flex gap--12 ph--8 mb--16">
          <Avatar initials={initials} />
          <div>
            <h3 className="fs--12 lh--copy w--max-180px t--truncate">{name}</h3>
            {role === "admin" ? (
              <Tag utilityClasses="bg--blue-v4">Admin</Tag>
            ) : (
              <Tag utilityClasses="bg--white-10">Member</Tag>
            )}
          </div>
        </header>
        <div className="bg--white-10 fs--10 pa--8 br--4">
          <p className="display--flex flex--items-center gap--4">
            <MdOutlineMailOutline color="#dee5eb" /> <span className="w--max-200px t--truncate">{email}</span>
          </p>
          <p className="display--flex flex--items-center gap--4">
            <MdRemoveRedEye color="#dee5eb" />
            <span className="w--max-200px t--truncate">Last active {last_active}</span>
          </p>
        </div>
      </article>
    </Popover>
  );
}

function ActivityFeed({ activities = [] }) {
  if (!activities.length) return <p className="mid-gray fs--12">There is no new activity right now.</p>;

  return (
    <div className="activity-feed__wrapper pb--32">
      {activities.map((activity) => {
        return (
          <div className="display--flex gap--8 mb--24" key={activity.id}>
            {activity.initials ? (
              <Avatar size="md" initials={activity.initials} />
            ) : (
              <Avatar size="md" initials={<Esp fill="blue" width={16} height={16} />} />
            )}
            <div className="display--flex flex--column gap--4 fs--12">
              <span>{activity.message}</span>
              <span className="gray">{activity.time_ago}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
