import { useContext, useState, useEffect } from "react";
import wretch from "wretch";
import { UserContext } from "../UserContext";

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

export default function useBlobFetch(endpoint) {
  const { currentUser } = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isFetching = true;

    async function getFile() {
      try {
        const response = wretch(`/organization/${currentUser.organizationId}/${endpoint}`)
          .content("application/pdf")
          .auth(csrfToken)
          .get();
        const blob = await response.blob();
        const file = URL.createObjectURL(blob);

        if (isFetching) setFile(file);
      } catch (error) {
        if (isFetching) setError(error.message);
      }
    }

    getFile();

    return () => {
      isFetching = false;
    };
  }, []);

  return { file, error };
}
