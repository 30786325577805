import React from "react";

const logoFills = {
  light: "#ffffff",
  medium: "#808fff",
  dark: "#25265e",
  blue: "#0059F5",
  bluev2: "#287dfc",
};

export const Esp = ({ className = "", width = 31, height = 27, fill = "light", hasWhiteDot = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 31" fill="none">
      <path
        className={className}
        d="M0 0h1.88a8.695 8.695 0 0 1 8.694 8.695v21.853C4.734 30.548 0 25.814 0 19.974V0zM12.454 10.104h1.88A8.695 8.695 0 0 1 23.03 18.8v11.75H12.454V10.104z"
        fill={logoFills[fill]}
      />
      <path
        d="M29.83 30.399a5.17 5.17 0 1 0 0-10.34 5.17 5.17 0 0 0 0 10.34z"
        fill={hasWhiteDot ? logoFills.light : logoFills[fill]}
      />
    </svg>
  );
};

export const Mosaic = ({ className = "", width = 42, height = 19, fill = "light", hasWhiteDot = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 46 23" fill="none">
      <path
        className={className}
        d="M28.917 20.34c-5.272 4.291-12.538 2.802-16.23-3.325L3.915 2.45c5.272-4.29 12.538-2.802 16.23 3.326l8.773 14.564zM45.816 20.34c-5.272 4.291-12.538 2.802-16.23-3.325L20.814 2.45c5.272-4.29 12.538-2.802 16.23 3.326l8.773 14.564z"
        fill={logoFills[fill]}
      />
      <circle cx="5.151" cy="17.848" r="5.151" fill={hasWhiteDot ? logoFills.light : logoFills[fill]} />
    </svg>
  );
};

export const Ovi = ({ className = "", width = 32, height = 24, fill = "light", hasWhiteDot = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 28" fill="none">
      <path
        className={className}
        d="M24.489 2.135a6.37 6.37 0 0 1 9.008 9.008L19.333 25.308a6.635 6.635 0 0 1-9.384 0l-4.317-4.317L24.49 2.135z"
        fill={logoFills[fill]}
      />
      <path
        d="M1.6 13.967a5.17 5.17 0 1 0 7.312-7.311 5.17 5.17 0 0 0-7.311 7.311z"
        fill={hasWhiteDot ? logoFills.light : logoFills[fill]}
      />
      <path
        className={className}
        d="M1.504 16.861v.001l4.128 4.13 4.223-4.223.094-.094-.001-.001-.093.095a5.972 5.972 0 0 1-8.35.092z"
        fill={logoFills[fill]}
      />
    </svg>
  );
};

export const RoundTable = ({ className = "", width = 20, height = 27, fill = "light", hasWhiteDot = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 31" fill="none">
      <path
        d="M17.887 10.433a5.216 5.216 0 1 0 0-10.433 5.216 5.216 0 0 0 0 10.433z"
        fill={hasWhiteDot ? logoFills.light : logoFills[fill]}
      />
      <path
        className={className}
        d="M0 0h1.897a8.773 8.773 0 0 1 8.773 8.773v22.052C4.777 30.825 0 26.048 0 20.155V0z"
        fill={logoFills[fill]}
      />
    </svg>
  );
};
